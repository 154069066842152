import React, {useContext} from "react";
import {SerachDispatchContext, setSearchKeyword} from '../contexts/serach';
import useI18n from '../hook/useI18n';

const Serach = () => {
    const {t} = useI18n();
    const serachDispatch = useContext(SerachDispatchContext);

    const handleSerachInput = (event)=>{
        return setSearchKeyword(serachDispatch, event.target.value)
    };

    const handleSerachReset = ()=>{
        return setSearchKeyword(serachDispatch, '')
    };
    return (
        <div>
            <form className="search">
                <div className="box">
                    <span className="magnifier">&#8981;</span>
                    <input onChange={handleSerachInput} type="text" className="field" placeholder={t('metaassets.asset.serach')}/>
                    <input onClick={handleSerachReset} type="reset" value="&#x2716;" className="close"/>
                </div>
                <div className="cat">
                    <button onClick={(e)=>{e.preventDefault()}}>{t('metaassets.asset.serachOfName')}</button>
                    <button onClick={(e)=>{e.preventDefault()}}>{t('metaassets.asset.serachOfCreator')}</button>
                    <button onClick={(e)=>{e.preventDefault()}}>{t('metaassets.asset.serachOfCerti')}</button>
                </div>
            </form>

        </div>
   )
};

export default Serach;