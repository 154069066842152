import React, {useContext, useState} from "react";
import certificating_title from "../assets/img/certificating_title.svg"
import {I18nContext} from "../contexts/lang";
import useI18n from '../hook/useI18n';
const CertificationModal = (props)=>{

    const {t} = useI18n();
    const locale = useContext(I18nContext);

    if(!props.show){
       return null
    }

    return (
        <div className="modal certificating_modal">
            <div className="actions">
                <button className="toggle-button" onClick={props.handleClose} >
                    ✖
                </button>
            </div>
            <div className="modalWrap">
                <div className="content">

                    <div className="certificating_wrap">
                        <div className="certificating_content">

                            <div className="certificating_header">
                                <div className="certificating_title">
                                    <img src={certificating_title} />
                                </div>
                                <div className="certificating_no">
                                    <span>NO.</span><span>{props.data.certi_no}</span>
                                </div>
                            </div>
                            <div className="certificating_body">
                                <div className="certificating_img_wrap">
                                    <img className="certificating_img"
                                         src={props.data.url}/>
                                </div>

                                <div className="certificating_img_info">
                                    <div className="certificating_title"><span>TITLE</span><span>{locale.getLocale() === 'zh-TW' ? `${props.data.name}` : `${props.data.name_en}`}</span>
                                    </div>
                                    <div className="certificating_artist">
                                        <span>ARTIST</span><span>{locale.getLocale() === 'zh-TW' ? `${props.data.creator}` : `${props.data.creator_en}`}</span></div>
                                    <div className="certificating_date"><span>DATE</span><span>{props.data.certi_date}</span>
                                    </div>
                                </div>

                            </div>
                            <div className="certificating_footer">
                                <img src={require(`../assets/img/logo_white.png`)} />
                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </div>
    )
};

export default CertificationModal;