import logo_group from '../assets/img/logo_group.png';
import useI18n from '../hook/useI18n';
import {Link} from 'react-router-dom';
import {useLocation} from 'react-router-dom';

const Footer = () => {
    const {t} = useI18n();
    const location = useLocation();
    const currentPath = location.pathname;

    let button = null;

    if (currentPath === '/service') {
        button = (
            <a target="_blank"
               href="https://docs.google.com/forms/d/e/1FAIpQLSdK6jpzvxuUHrFXFDXzhwg2sP6MBg3YAV84KY-JsSmJjCVvig/viewform?pli=1">
                {t('metaassets.footer.service')}
            </a>
        )
    } else {
        button = (
            <Link to="/service">
                {t('metaassets.footer.service')}
            </Link>
        )
    }


    return (
        <div>
            <footer className="nav">
                <a href="/#art">
                    <div className="logo">
                        <img src={logo_group} alt="logo"/>
                    </div>
                </a>
                <nav className="menu">
                    <ul>
                        <li>
                            <a target="_blank" href="https://virtual.metaassets.asia/collect/inquire">
                                {t('metaassets.footer.purches')}
                            </a>
                        </li>
                        {/*<li><a href="https://www.pinqueue.com/">{t('metaassets.footer.pinqueue')}</a></li>*/}
                        <li>
                            <Link to="/service">
                                {t('metaassets.footer.service')}
                            </Link>
                        </li>
                        <li><a href="https://www.postwings.art/">{t('metaassets.footer.pinpinbox')}</a></li>
                        <li><a href="mailto:sung@vmage.com.tw?subject=聯絡我們">{t('metaassets.footer.contact')}</a>
                        </li>
                    </ul>
                    <p>{t('metaassets.footer.right')}</p>
                </nav>
            </footer>
            <div className="servicing_wrap">
                <div className="arrow-container animated fadeInDown">
                    <div className="arrow-2 servicing">
                        {button}
                    </div>
                    <div className="arrow-1 animated hinge infinite zoomIn"></div>
                </div>
            </div>
            <div className="scrolltop-wrap">
                <a href="#" role="button" alt="回頂端">
                    <svg xmlns="http://www.w3.org/2000/svg">
                        <path id="scrolltop-bg" d="M0 0h48v48h-48z"></path>
                        <path id="scrolltop-arrow" d="M14.83 30.83l9.17-9.17 9.17 9.17 2.83-2.83-12-12-12 12z"></path>
                    </svg>
                </a>
            </div>
        </div>

    )
};

export default Footer;
