import { createContext } from 'react';
import React, { useState } from 'react';

export const I18nContext = createContext({
   t:()=> '',
   getLocale: ()=>{},
   setLocale: ()=>{},
});

const I18nProvider = (props) =>{
   const [locale, setLocale] = useState('en-US');


   const il8n = {
       t: key => props.translations[locale][key],
       getLocale: ()=>locale,
       setLocale: locale=> setLocale(locale)
   };

   return (
       <I18nContext.Provider value={il8n}>
           {props.children}
       </I18nContext.Provider>
   )
};

export default I18nProvider;