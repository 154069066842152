export default {
    "metaassets.head.about": "メタアセットについて",
    "metaassets.head.art": "アートコレクション",
    "metaassets.head.ip": "おすすめ作品",
    "metaassets.head.asset": "資産検索",
    "metaassets.head.tutorial": "投資の手引き",
    "metaassets.head.content": "未来を見据えた資産投資",
    "metaassets.head.link": "バーチャルギャラリー",
    "metaassets.head.serach": "作品",
    "metaassets.banner.promise1": "オリジナル作品認定",
    "metaassets.banner.promise2": "本物と偽物を識別するための特許技術",
    "metaassets.banner.promise3": "アート資産検索データベース",
    "metaassets.home.head": "将来の世界資産に投資する",
    "metaassets.home.virtual": "仮想現実（VR）表示",
    "metaassets.home.invest": "現代美術 X ブロックチェーンの新たな運用を",
    "metaassets.home.investOfContent": "ブロックチェーンの技術を用いて発行されるアート資産IDトークンとは、デジタル資産を認証するための専属IDであり、信頼でき、かつ改ざんできないNFTの特性を生かして発行されます。Metaassets は現代美術としてのデジタル資産の発行元を認定することで乱用を防止し、アートと資産価値のコンセプトをデジタルの世界に取り込み、貴重な現代美術作品の新たなプラットフォームとなるとともに、コレクターとしての投資家に対して法的に認められたアート資産IDトークンを提供することによって、実体作品とデジタルアートの所有権を確実にします。",
    "metaassets.home.art": "コレクション",
    "metaassets.home.qty": "Mint: ",
    "metaassets.home.sell": "収蔵",
    "metaassets.home.position": "オブジェクトの位置",
    "metaassets.home.ip": "おすすめ作品",
    "metaassets.home.enpower": "投資家育成プログラム",
    "metaassets.home.about": "メタアセットについて",
    "metaassets.home.aboutOfContent": "Metaassets について \n" +
        "Metaassetsチームはブロックチェーンの技術を用いてアーティストに協力します。現代美術向けのWeb 3.0エコシステムを構築し、バーチャルとリアルが統合されたアート資産IDトークン発行により、アーティストが自分専用のメタバースを手早く作り上げるお手伝いをします。さらに、バーチャルとリアルが統合されたオンライン展示サービスを通して、場所の制約を取り払い、アート作品露出の時間と範囲をグローバルレベルで拡大し、ボーダーレスな現代美術展示プラットフォームサービスを提供致します。",
    "metaassets.home.service": "サービス項目",
    "metaassets.home.service_item1": "NFT発行者認定",
    "metaassets.home.service_item2": "バーチャル リアリティ ショー",
    "metaassets.home.service_item3": "アートコレクション支払いゲートウェイ（クレジットカード）の設定サービス",
    "metaassets.home.service_item4": "美術品検査データベース",
    "metaassets.home.team": "法定証明",
    "metaassets.home.bottom": "将来のメタ資産に投資する",
    "metaassets.home.bottomOfLink": "認定を受ける",
    "metaassets.home.more": "アンビグラム",
    "metaassets.sellmodal.title": "美術品購入情報ページ",
    "metaassets.sellmodal.name": "クリエイター",
    "metaassets.sellmodal.certi_no": "クリエイター",
    "metaassets.sellmodal.exchange": "取引所",
    "metaassets.sellmodal.qty": "ミント",
    "metaassets.sellmodal.price": "リザーブプライス",
    "metaassets.sellmodal.status": "原画状態",
    "metaassets.sellmodal.size": "サイズ",
    "metaassets.sellmodal.detail": "詳しくは",
    "metaassets.sellmodal.na": "まだ発売されていません",
    "metaassets.sellmodal.purchase": "購入",
    "metaassets.sellmodal.contact": "チャット",
    "metaassets.asset.title": "アートワークを検索する",
    "metaassets.asset.term": "契約条件：",
    "metaassets.asset.termOfContent": "Metaassets アート資産IDトークンの購入者（オークションの落札者およびその譲受人と将来の承継人を指し、「購入者」という）は、Metaassets アート資産IDトークンを収蔵するとき、以下の条件を承諾し、同意することになります。",
    "metaassets.asset.serach": "さらに多くのアート作品",
    "metaassets.asset.serachErr": "検索結果が見つかりません:",
    "metaassets.asset.serachOfName": "案件名",
    "metaassets.asset.serachOfCreator": "クリエイター",
    "metaassets.asset.serachOfCerti": "認定コード",
    "metaassets.asset.nft": "アートワーク",
    "metaassets.asset.exchange": "取引所",
    "metaassets.asset.name": "クリエータ",
    "metaassets.asset.investment": "認証ステータス",
    "metaassets.asset.released": "認証済み",
    "metaassets.asset.unreleased": "未發證",
    "metaassets.asset.whiteitem": "ホワイトリスト",
    "metaassets.asset.qty": "ミント",
    "metaassets.asset.price": "最低料金",
    "metaassets.asset.category": "類別",
    "metaassets.asset.entity": "実在物",
    "metaassets.asset.size": "サイズ",
    "metaassets.asset.crypto": "暗号通貨",
    "metaassets.asset.creator": "クリエイター",
    "metaassets.asset.authorize": "委託",
    "metaassets.asset.release": "販売",
    "metaassets.asset.certi_no": "認定コード",
    "metaassets.asset.certi_date": "認定日",
    "metaassets.asset.Important": "免責の表明：お客様の目的を問わず、弊社が提供するコンテンツは、財務上、法律上のアドバイス、またはいかなる形式のアドバイスも構成するものではありません。",
    "metaassets.footer.pinqueue": "クロスメディアプラットフォーム",
    "metaassets.footer.pinpinbox": "価値創造プラットフォーム",
    "metaassets.footer.contact": "お問い合わせ",
    "metaassets.footer.service": "パッケージ",
    "metaassets.footer.purches": "注文追跡",
    "metaassets.footer.right": "元宇宙資産股份有限公司 禁無断転載",
    "metaassets.tutorial.head": "投資するまでの3ステップ",
    "metaassets.tutorial.headContent": "NFT投資はイーサリアムで行われます、次の3ステップでコレクターの仲間入りができます",
    "metaassets.tutorial.step1": "イーサリアムと法定通貨の交換の便を図るため、仮想通貨取引所に登録します",
    "metaassets.tutorial.step2": "Step2 NFTを管理するためのウォレットを申請する",
    "metaassets.tutorial.step2-2": "（ニーモニックフレーズはいわば通帳なので、外部に漏れないよう、しっかり管理してください）",
    "metaassets.tutorial.step3": "Step3 これでコレクターになれます！必ずホワイトリストに加入しましょう、NFTが当たる抽選に無料で参加できます！",
    "metaassets.block.tilte": "アート資産IDトークン 5つのメリット",
    "metaassets.block.item1": "アート資産の収蔵証明",
    "metaassets.block.item2": "特許技術による贋作防止",
    "metaassets.block.item3": "転売時の作者への還元",
    "metaassets.block.item4": "コレクション展示の管理",
    "metaassets.block.item5": "グローバル P2P 取引証明",
    "metaassets.service.title1": "Postwings アート作品管理サービス",
    "metaassets.service.title1-1": "《ご自身の作品管理  ベストソリューション提供》",
    "metaassets.service.content1": "アート作品管理業務が必要な理由とは？",
    "metaassets.service.content1-1": "アート作品管理システムが構築されていると、アーティストの作品創作と収蔵の利便性が増します。展示会に参加するのを除くと、ほとんどが倉庫で眠っている状態で、見本市への参加、作品集の制作のときに他者の目に止まる程度であり、専属のバーチャルギャラリーを立ち上げて、作品の露出度を向上させます。",
    "metaassets.service.playbutton-artist": "私は芸術家です",
    "metaassets.service.playbutton-collect": "私はコレクターです",
    "metaassets.service.title2": "コレクションよくある管理上の問題",
    "metaassets.service.content2-1": "コレクションをうまくアーカイブできない",
    "metaassets.service.content2-2": "作品数が多すぎて、さがし出すのに時間がかかる",
    "metaassets.service.content2-3": "作品をSNSで共有できない",
    "metaassets.service.content2-4": "作品流通後における認定の問題",
    "metaassets.service.content2-5": "オリジナルの実体展示にかかわる問題",
    "metaassets.service.content2-6": "作品の価値が向上した後の真贋識別問題",
    "metaassets.service.title3": "わずか 4 ステップ",
    "metaassets.service.title3-1": "《自分だけのアート作品管理システム構築》",
    "metaassets.service.content3": "契約締結アート作品データとファイル提供",
    "metaassets.service.content3-1": "アート作品登録データベース&作品IDの生成",
    "metaassets.service.content3-2": "オンラインバーチャルギャラリー展示＆SNSでの共有、鑑賞の約束、収蔵",
    "metaassets.service.content3-3": "クリエータ専用ブロックチェーンベースのスマート契約管理",
    "metaassets.service.title4": "なぜ、Metaassetsなのか？",
    "metaassets.service.title4-1": "《Postwings 三つの強み》",
    "metaassets.service.conetent4-1": "独自のダブル特許",
    "metaassets.service.conetent4-1-1": "1.作品認定＆真贋証明システムのダブル特許（中華民国特許）",
    "metaassets.service.conetent4-2": "バーチャルギャラリー",
    "metaassets.service.conetent4-2-1": "2.バーチャルギャラリーで自分専用の画廊を運営",
    "metaassets.service.conetent4-3": "実体展示",
    "metaassets.service.conetent4-3-1": "3.実体展示スペースのレンタル、キュレーション代行業務",
    "metaassets.service.title5": "パッケージ",
    "metaassets.service.content5": "パッケージ",
    "metaassets.service.content5-common": "(税別)",
    "metaassets.service.content5-common-1": "(年契約)",
    "metaassets.service.content5-common-A": "クラシックアーティスト",
    "metaassets.service.content5-common-B": "エリートエクスクルーシブマーケティングパッケージ",
    "metaassets.service.content5-common-C": "マスターオブアーツパッケージ",
    "metaassets.service.content5-common-2": "決済の流れを設定可能",
    "metaassets.service.content5-common-3": "もっと見る",
    "metaassets.service.content5-A-1": "15点の作品出展と管理",
    "metaassets.service.content5-A-2": "専用個人ウェブページ",
    "metaassets.service.content5-A-3": "3Dギャラリー1室",
    "metaassets.service.content5-A-5": "専用アーティストカード1箱と印刷データ クレジットカード取引の電子記録と 証明書サービス（取引ごとに手数料15％",
    "metaassets.service.content5-A-6": "作品追加1点ごとにNTD$500",
    "metaassets.service.content5-A-7": "マーケティングの展示",
    "metaassets.service.content5-A-7-1": "波翼マーケティングの展示",
    "metaassets.service.content5-A-7-2": "波翼アートニュース１部",
    "metaassets.service.content5-A-7-3": "アート作品価格に対する25％の販売手数料",
    "metaassets.service.content5-A-8": "追加購入特典 プロの写真家による作品撮影NTD$ 500元/点( 通常価格： NTD$ 1000元/点)",
    "metaassets.service.content5-B-1": "35点の作品出展と管理",
    "metaassets.service.content5-B-2": "3Dギャラリーの提供",
    "metaassets.service.content5-B-3": "専用アート名刺1箱と印刷",
    "metaassets.service.content5-B-4": "データ クレジットカード取引の電子記録と 証明書サービス（取引ごとに手数料15％",
    "metaassets.service.content5-B-5": "作品追加1点ごとにNTD$300元",
    "metaassets.service.content5-B-6": "マーケティングの展示",
    "metaassets.service.content5-B-6-1": "波翼マーケティングの展示",
    "metaassets.service.content5-B-6-2": "波翼アートニュース１部（ YAHOOを含む3つのメディアでの確実な展示）",
    "metaassets.service.content5-B-6-3": "プロのカメラマンによる肖像写真撮影（1枚)",
    "metaassets.service.content5-B-6-4": "１本の短いソーシャルメディア動画紹介",
    "metaassets.service.content5-B-6-5": "アート作品の価格に対する25％の販売手数料",
    "metaassets.service.content5-C-1": "150点の作品出展と管理",
    "metaassets.service.content5-C-2": " 個人専用ウェブページ",
    "metaassets.service.content5-C-3": "3 つの 3D アート ギャラリー (3 つの展示を開催可能)、限定アート名刺と印刷ファイルのボックス 5 つ",
    "metaassets.service.content5-C-4": "ART ON AIRの参加資格",
    "metaassets.service.content5-C-5": "キャッシュフローカード取引電子記録・証明書サービス（1取引あたり15％の手数料）",
    "metaassets.service.content5-C-6": "150 ドルでもう 1 作品追加",
    "metaassets.service.content5-C-7": "マーケティングの展示",
    "metaassets.service.content5-C-7-1": "波翼マーケティングの展示& Maggie Art Talksでの展示",
    "metaassets.service.content5-C-7-2": "波翼公式ウェブサイト上での3つのバナー展示",
    "metaassets.service.content5-C-7-3": "150 ドルでもう 1 作品追加",
    "metaassets.service.content5-C-7-4": "波翼アートニュース3部(YAHOOを含む3つのメディアでの確実な展示)",
    "metaassets.service.content5-C-7-5": "プロのカメラマンによる個人画像の撮影（2枚）",
    "metaassets.service.content5-C-7-6": "複数の短いソーシャルメディア動画紹介の制作 アート作品の",
    "metaassets.service.content5-C-7-7": "アート作品価格に対する25％の販売手数料",
    "metaassets.service.title6": "展示スペースの実例",
    "metaassets.service.title7": "Q&A? よくあるご質問",
    "metaassets.service.content7-1-1": "1.METAASSETSアート資産管理認証とは？",
    "metaassets.service.content7-1-2": "ANS：弊社はブロックチェーンの技術でアートを管理する業務を提供しており、作品のトレースから流通、露出、追跡、鑑定まで、弊社のアート資産管理システムで解決できます。",
    "metaassets.service.content7-2-1": "2.なぜ、アート管理の業務が必要なのですか？",
    "metaassets.service.content7-2-2": "ANS：アーティストは創作に専念しており、年月を経て作品の数が多くなるのに加えて、展示と販売の機会が増えると、作品の追跡管理がむずかしくなります。さらに、展示されるチャンスがなく、人目に触れずに倉庫で眠っている作品も多いことから、アート管理業務が必要になるわけです。",
    "metaassets.service.content7-3-1": "3.高解像度データファイルを作成していないときは？",
    "metaassets.service.content7-3-2": "ANS：METAASSETSが高解像度PHASE ONEカメラによるアート撮影サービスを提供します。作品を波翼芸術中心（POSTWINGS）に送り、あるいはスタッフが機材を携えて出向いて撮影します。1.5億画素で撮影してから、特許認証とAIによる真贋証明に用います。",
    "metaassets.service.content7-4-1": "4.作品を高解像度データファイル化すると、ダウンロードしてコピーされるのでは？",
    "metaassets.service.content7-4-2": "ANS：高解像度のアートファイルは、ブロックチェーン上の証明書発行のみに使用されます。作品はオンラインギャラリーやデータベースのウェブサイトで提供されますが、盗難後の不正使用を防ぐために圧縮されます。ただし、この圧縮はアートワークの鑑賞や体験に影響を与えません。",
    "metaassets.service.content7-5-1": "5.作品の認定管理だけを行い、ブロックチェーンを適用しないのは可能ですか？",
    "metaassets.service.content7-5-2": "ANS：可能です。作品にブロックチェーンを適用するかどうかはアーティストが決定できます。ブロックチェーンのメリットは作品の発行元を認定でき、改ざんできないことであり、取引価格の開示または非開示も選択できます。",
    "metaassets.service.content7-6-1": "6.実体作品の展示スペースだけを利用することができますか？",
    "metaassets.service.content7-6-2": "ANS：できます。METAASSETSは波翼芸術中心（POSTWINGS）と提携して、すべてのアーティスト／画廊／コレクター／協会に実体作品展示スペースをレンタルしています。",
    "metaassets.service.content7-7-1": "7.バーチャルギャラリーを立ち上げると、必ずMETAASSETSのサイトにアップしなければならないのですか？",
    "metaassets.service.content7-7-2": "ANS：必ずしもそうではありません。METAASSETS認定のデータベースにアップするか、プライベートな収蔵と共有にのみ用いるかは、本人が決定できます。",
    "metaassets.service.content7-8-1": "8.どうすれば、バーチャルギャラリーをSNSで共有できるのですか？",
    "metaassets.service.content7-8-2": "ANS：バーチャルギャラリーには専属のサイトがあり、Line、FB、IGといったSNSの交友プラットフォームで友達やコレクターと共有し、露出度を向上させて展示や流通のチャンスを広げることができます。",
    "metaassets.service.content7-9-1": "9.バーチャルギャラリーだと実体作品を展示できず、実際に見た時の感じを伝えられないのではないかと心配です。",
    "metaassets.service.content7-9-2": "ANS：心配いりません。弊社のバーチャルギャラリーだと、実物を鑑賞するために作者と約束できる機能があり、都合のいい時間を自由に決められます。",
    "metaassets.service.content7-10-1": "10.作品を販売するとき、どうやってアート資産IDトークンを買い手に発行するのですか？",
    "metaassets.service.content7-10-2": "ANS：まずMetaMask ウォレットを申請します。アート資産IDトークンの取得方法は2種類。ひとつは、バーチャルギャラリーに作品をアップするとき、アート資産IDトークンを購入し、取引成立時に買い手指定のMetaMaskウォレットに移転する方法。もうひとつは、弊社METAASSETSまでアート資産IDトークン発行を申請し、指定したMetaMaskウォレットまで移転する方法です。",
    "metaassets.service.content8-11-1": "芸術資産シリアル番号の説明：各作品には独自のシリアル番号があり、以下のコード説明があります：",
    "metaassets.service.content8-11-2": "ANS: FAは完全な認可モードを示し（知的財産権を含む）。/PAは部分的な認可モードを示し（知的財産権を除く）。/OWは所有権移転モードを示します（純粋な芸術作品の移転）。",
    "metaassets.service.button": "お問い合わせ",
    "metaassets.service.tel": "お問い合わせ専用電話番号",
    "metaassets.tip.title": "Metaassets認証コードの権限説明：",
    "metaassets.tip.content1": "Lic-FA は全権限（著作権を含む）の権限モードを表します。",
    "metaassets.tip.content2": "Lic-PA は部分権限（著作権を含まない）の権限モードを表します。",
    "metaassets.tip.content3": "Lic-OW は所有権移転（純粋な芸術品の移転のみ）の権限モードを表します。",







}