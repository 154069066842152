import React, {useContext} from "react";
import {I18nContext} from '../contexts/lang';
import ViewImg from '../hook/viewImg';
import ViewFrame from '../hook/viewFrame';
const Asset = ({handleModal, handleTipModal, handleSellModal, data, id, currentPage, PageSize})=>{
    const {name, name_en, url, creator, creator_en,
        status, qty,creation_year, price,
        category, category_en, medias, medias_en, entity, size, open_sea, pinpinbox, auth, certi_no, certi_date} = data;

    const locale = useContext(I18nContext);
    let media = medias[0]? `(${medias[0]})`: '';
    let media_en = medias_en[0]? `(${medias_en[0]})`: '';
    const transRelease = (locale, release)=>{

        if(release){
            const trans = {
                "en-US": "Certificate issued",
                "zh-TW": "已發證",
                "ja-JP": "認定済み",
            };

            return trans[locale];
        }

        const trans = {
            "en-US": "Certificate not issued",
            "zh-TW": "未發證",
            "ja-JP": "未認定",
        };

        return trans[locale];
    };

    return (
        <tr>
            <td className="no headcol">{id + 1 + (currentPage -1) * PageSize}</td>
            <td className="item_img headcol2"><ViewImg src={url} alt={locale.getLocale() === 'zh-TW' ? name: name_en}/></td>
            {/*<td><a href={open_sea}>OpenSea</a></td>*/}
            <td><a href={open_sea} target="_blank">OpenSea</a></td>
            {/*<td><ViewFrame url={pinpinbox} name={locale.getLocale() === 'zh-TW'? name: name_en} /></td>*/}
            <td>{locale.getLocale() === 'zh-TW'? name: name_en}</td>
            {/*<td>{status === 1 || status === 2? <ViewFrame url={open_sea} name={transRelease(locale.getLocale(), 1)}/> : <ViewFrame url={open_sea} name={transRelease(locale.getLocale(), 0)}/>}</td>*/}
            <td>{status === 1 ? <a href={open_sea}  target="_blank">{transRelease(locale.getLocale(), 1)}</a> :`${transRelease(locale.getLocale(), 0)}`}</td>
            <td>{qty}</td>
            {/*<td><ViewFrame url={pinpinbox} name={locale.getLocale() === 'zh-TW'? creator: creator_en} /></td>*/}
            <td>{locale.getLocale() === 'zh-TW'? creator: creator_en}</td>
            {/*<td>{price}</td>*/}
            <td>{locale.getLocale() === 'zh-TW'? `${category}${media}`: `${category_en}${media_en}`}</td>
            {/*<td>{entity}</td>*/}
            <td>{size}</td>
            <td className="agent">{creation_year}</td>
            <td>
                <a  onClick={handleTipModal} href="#">
                {auth}
                </a>
            </td>
            <td><a href="#" onClick={()=>{handleModal(data)}}>{certi_no}</a></td>
            <td>{certi_date}</td>
        </tr>
    )
};

export default Asset;