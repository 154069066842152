import React from 'react';
import classnames from 'classnames';
import {usePagination, DOTS} from '../hook/usePagination';

const Pagination = (props) => {
    const {
        onPageChange,
        totalCount,
        siblingCount = 1,
        currentPage,
        pageSize,
        className
    } = props;

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize
    });

    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    let lastPage = paginationRange[paginationRange.length - 1];

    return (
        <div className={classnames({[className]: className})}>
            <a className={classnames('prev',{
                disabled: currentPage === 1
            })} onClick={onPrevious}>&#8249;</a>
            {
                paginationRange.map((pageNumber, index) => {
                    if (pageNumber === DOTS) {
                        return <a key={index}>&#8230;</a>
                    }
                    return (
                        <a key={index} className={classnames({
                            selected: pageNumber === currentPage
                        })}
                           onClick={() => onPageChange(pageNumber)}
                        >
                            {pageNumber}
                        </a>
                    )
                })
            }
            <a className={classnames('next', {
                disabled: currentPage === lastPage
            })} onClick={onNext}>&#8250;</a>
        </div>

    )
};

export default Pagination;