import React, { useReducer, createContext} from "react";

const initialState = {
    isModalOpen: false,
};

export const ModalStateContext = createContext();
export const ModalDispatchContext = createContext();

const reducer = (state, action) => {
    switch (action.type) {
        case "TOGGLE_MODAL":
            return {
                ...state,
                isModalOpen: !state.isModalOpen
            };
        default:
            throw new Error(`Unknown action: ${action.type}`);
    }
};

const ModalProvider = ({children})=>{
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <ModalDispatchContext.Provider value={dispatch}>
            <ModalStateContext.Provider value={state}>
                {children}
            </ModalStateContext.Provider>
        </ModalDispatchContext.Provider>
    );
};

export const toggleModal = (dispatch) => {
    return dispatch({
        type: "TOGGLE_MODAL"
    });
};

export default ModalProvider;