export default {
    "metaassets.head.about": "關於Metaassets",
    "metaassets.head.art": "藝術典藏",
    "metaassets.head.ip": "收藏推薦",
    "metaassets.head.asset": "資產搜尋",
    "metaassets.head.tutorial": "投資教學",
    "metaassets.head.content": "投資你的未來世界資產",
    "metaassets.head.link": "虛擬實境展示",
    "metaassets.head.serach": "更多藝術品",
    "metaassets.banner.promise1": "藝術資產憑證發行",
    "metaassets.banner.promise2": "專利仿偽查驗技術",
    "metaassets.banner.promise3": "藝術朔源查詢資料庫",
    "metaassets.home.head": "投資你的未來世界資產",
    "metaassets.home.virtual": "虛擬實境展示",
    "metaassets.home.invest": "當代藝術X區塊鏈新運用",
    "metaassets.home.investOfContent": "藝術資產憑證是利用區塊鏈技術發行，運用NFT可信任且無法竄改的特性、發行專屬身份的數位資產憑證，Metaassets為當代藝術數位資產來源提供認證朔源，杜絕濫用發行更賦予實體藝術及資產價值的概念引進數位世界，為珍貴的當代藝術品開闢新天地，更為投資藏家提供具法律保障的藝術資產憑證，確保實體與數位藝術品的擁有權。",
    "metaassets.home.art": "藝術典藏",
    "metaassets.home.qty": "鑄造件數: ",
    "metaassets.home.sell": "收藏",
    "metaassets.home.position": "物件位置",
    "metaassets.home.ip": "收藏推薦",
    "metaassets.home.enpower": "投資賦能計畫",
    "metaassets.home.about": "關於 Metaassets",
    "metaassets.home.aboutOfContent": "Metaassets團隊利用區塊鏈技術，協助當代藝術的藝術工作者，持續打造屬於當代藝術的Web3.0生態運用，虛實整合的藝術資產憑證發行服務，讓當代藝術的藝術家可以快速打造屬於自己的當代藝術元宇宙，透過線上虛實整合的展覽服務，擴大當代藝術品的曝光時間與廣度，不受地域影響，也可以與國際接軌，打造無國界的當代藝術展覽服務。",
    "metaassets.home.service": "服務項目",
    "metaassets.home.service_item1": "虛擬展覽客製化服務",
    "metaassets.home.service_item2": "藝術資產憑證發行管理服務",
    "metaassets.home.service_item3": "藝術品收藏金流（信用卡）設定服務",
    "metaassets.home.service_item4": "藝術品查驗資料庫",
    "metaassets.home.team": "法律認證",
    "metaassets.home.bottom": "投資你未來的元資產",
    "metaassets.home.bottomOfLink": "我想加入認證",
    "metaassets.home.more": "翻轉藝術",
    "metaassets.sellmodal.title": "藝術品購買資訊頁",
    "metaassets.sellmodal.name": "創作者",
    "metaassets.sellmodal.certi_no": "資產編號",
    "metaassets.sellmodal.exchange": "交易所",
    "metaassets.sellmodal.qty": "鑄造",
    "metaassets.sellmodal.price": "價格",
    "metaassets.sellmodal.size": "尺寸",
    "metaassets.sellmodal.status": "原畫狀態",
    "metaassets.sellmodal.detail": "更多資訊",
    "metaassets.sellmodal.na": "尚未開賣",
    "metaassets.sellmodal.purchase": "購買",
    "metaassets.sellmodal.contact": "聊聊",
    "metaassets.asset.title": "搜尋藝術品",
    "metaassets.asset.term": "條款和條件：",
    "metaassets.asset.termOfContent": "Metaassets 藝術資產憑證的任何購買者（即拍賣的獲勝者及其受讓人和其後的繼任者，“購買者”） 在收藏 Metaassets 藝術資產憑證時應承認並同意以下條款和條件： 買方應獲得 Metaassets 藝術資產憑證 （NFT） 的所有權， 但 Metaassets 藝術資產憑證的任何版權、商標權或任何其他知識產權不得轉讓給買方，仍歸原著作者所有。",
    "metaassets.asset.serach": "請輸入想要搜尋",
    "metaassets.asset.serachErr": "找不到搜尋結果:",
    "metaassets.asset.serachOfName": "案件名稱",
    "metaassets.asset.serachOfCreator": "創作人",
    "metaassets.asset.serachOfCerti": "認證編號",
    "metaassets.asset.nft": "藝術品",
    "metaassets.asset.exchange": "交易所",
    "metaassets.asset.name": "案件名稱",
    "metaassets.asset.investment": "發證狀態",
    "metaassets.asset.released": "已發證",
    "metaassets.asset.unreleased": "未發證",
    "metaassets.asset.whiteitem": "白名單",
    "metaassets.asset.qty": "鑄造",
    "metaassets.asset.price": "發行底價",
    "metaassets.asset.category": "類別",
    "metaassets.asset.entity": "實體",
    "metaassets.asset.size": "尺寸",
    "metaassets.asset.crypto": "加密貨幣",
    "metaassets.asset.creator": "創作人",
    "metaassets.asset.authorize": "授權方式",
    "metaassets.asset.release": "年分",
    "metaassets.asset.certi_no": "認證編號",
    "metaassets.asset.certi_date": "認證日期",
    "metaassets.asset.Important": "重要免責聲明: 不論您的目的為何，我們所提供內容之任何部分均不構成財務建議、法律建議或任何形式的建議。利用或根據我們的內容應謹慎並自負風險。根據我們的內容作出決策前，請務必自行研究、回顧、分析並驗證這些資訊。交易是牽涉高度風險的活動，可能導致重大損失，因此決策前，請向您的財務顧問諮詢。我們網站上的內容不涉及任何遊說或要約之意圖。",
    "metaassets.coop.head": "合作邀請",
    "metaassets.footer.pinqueue": "跨媒體平台",
    "metaassets.footer.pinpinbox": "postwings波翼藝術中心",
    "metaassets.footer.contact": "聯絡我們",
    "metaassets.footer.service": "了解方案",
    "metaassets.footer.purches": "訂單查詢",
    "metaassets.footer.right": "©元宇宙資產股份有限公司 版權所有",
    "metaassets.tutorial.head": "投資教學步驟",
    "metaassets.tutorial.headContent": "因投資NFT交易貨幣為以太幣，三步驟即可加入藏家行列",
    "metaassets.tutorial.step1": "註冊一個數位貨幣交易所，方便管理以太幣和匯兌回法幣",
    "metaassets.tutorial.step2": "申請一個錢包來管理你的NFT原畫",
    "metaassets.tutorial.step2-2": "(助記詞相當於你的存簿，請小心管理防外洩)",
    "metaassets.tutorial.step3": "恭喜您！可以開始當藏家了，別忘記加入我們的白名單群，可享有免費抽獎獲得NFT原畫的機會喔！",
    "metaassets.block.tilte": "藝術資產憑證五大優勢",
    "metaassets.block.item1": "藝術資產收藏證明",
    "metaassets.block.item2": "專利防偽技術",
    "metaassets.block.item3": "著作者持續版稅收入",
    "metaassets.block.item4": "收藏品展示管理",
    "metaassets.block.item5": "全球點對點交易證明",
    "metaassets.service.title1": "Postwings 藝術品管理服務",
    "metaassets.service.title1-1": "《為您的藝術品提供最佳解決方案》",
    "metaassets.service.content1": "為什麼要建立專屬藝術品管理服務?",
    "metaassets.service.content1-1": "建立專屬藝術品管理服務，可以讓藝術家便於管理藝術品創作與收藏，藝術作品除了參加展覽外，大多都成塵封在倉庫，需要等到參加藝博會與製作畫冊才容易被看見，故建立自己的專屬虛擬藝廊，可以增加能見度。",
    "metaassets.service.playbutton-artist": "我是藝術家",
    "metaassets.service.playbutton-collect": "我是收藏家",
    "metaassets.service.title2": "藝術收藏品常見的管理問題",
    "metaassets.service.content2-1": "藝術收藏品資料零散缺乏管理",
    "metaassets.service.content2-2": "藝術收藏品數量太多翻找欣賞不易",
    "metaassets.service.content2-3": "藝術收藏品社群曝光不易",
    "metaassets.service.content2-4": "藝術收藏品流通後來源認定問題",
    "metaassets.service.content2-5": "藝術收藏品實體原作展覽需求問題",
    "metaassets.service.content2-6": "藝術收藏品增值後真偽辨識問題",
    "metaassets.service.title3": "簡單四個步驟",
    "metaassets.service.title3-1": "《建立你的專屬藝術品管理服務》",
    "metaassets.service.content3": "簽署合約提供藝術作品資料與檔案",
    "metaassets.service.content3-1": "藝術作品登錄資料庫&產生專屬藝術品身分證",
    "metaassets.service.content3-2": "線上虛擬藝廊佈展＆分享社群、約定看畫、收藏",
    "metaassets.service.content3-3": "撰寫藝術品專屬智能管理合約寫入區塊鏈",
    "metaassets.service.title4": "為什麼要找我們？",
    "metaassets.service.title4-1": "《Postwings 三大服務優勢》",
    "metaassets.service.conetent4-1": "獨家專利",
    "metaassets.service.conetent4-1-1": "1.擁有藝術品認證＆藝術品防偽查驗雙專利 （中華民國專利",
    "metaassets.service.conetent4-2": "虛擬藝廊",
    "metaassets.service.conetent4-2-1": "2.專業虛擬藝廊服務，經營自己專屬的藝廊",
    "metaassets.service.conetent4-3": "實體展覽",
    "metaassets.service.conetent4-3-1": "3.專屬實體合作展覽空間可租賃，完善的策展需求代工服務",
    "metaassets.service.title5": "會員方案",
    "metaassets.service.content5": "方案",
    "metaassets.service.content5-common": "(未稅)",
    "metaassets.service.content5-common-1": "(年約)",
    "metaassets.service.content5-common-A": "經典藝術家",
    "metaassets.service.content5-common-B": "菁英專屬行銷",
    "metaassets.service.content5-common-C": "全方位大師",
    "metaassets.service.content5-common-2": "可設定金流",
    "metaassets.service.content5-common-3": "了解更多",
    "metaassets.service.content5-A-1": "15件作品與上架管理",
    "metaassets.service.content5-A-2": "專屬個人網頁",
    "metaassets.service.content5-A-3": "3D藝廊一間",
    "metaassets.service.content5-A-4": "專屬藝術名片一盒及印刷檔",
    "metaassets.service.content5-A-5": "金流刷卡交易電子紀錄與證書服務(每筆交易15%手續費)",
    "metaassets.service.content5-A-6": "作品增加乙件$500元",
    "metaassets.service.content5-A-7": "行銷曝光",
    "metaassets.service.content5-A-7-1": "波翼藝術社群曝光",
    "metaassets.service.content5-A-7-2": "波翼藝術快訊新聞乙則",
    "metaassets.service.content5-A-7-3": "25%的藝術品代銷費",
    "metaassets.service.content5-A-8": "專業作品拍攝加購優惠500元/件(原價$1000元)",
    "metaassets.service.content5-B-1": "35件作品與上架管理",
    "metaassets.service.content5-B-2": "3D藝廊一間",
    "metaassets.service.content5-B-3": "專屬藝術名片一盒及印刷檔",
    "metaassets.service.content5-B-4": "金流刷卡交易電子紀錄與證書服務 (每筆交易15%手續費)作品增加乙件＄300元",
    "metaassets.service.content5-B-5": "作品增加乙件＄300元",
    "metaassets.service.content5-B-6": "行銷曝光",
    "metaassets.service.content5-B-6-1": "波翼藝術社群曝光",
    "metaassets.service.content5-B-6-2": "波翼藝術快訊新聞乙則（保證露出3個媒體含YAHOO）",
    "metaassets.service.content5-B-6-3": "個人專業形象照拍攝乙張",
    "metaassets.service.content5-B-6-4": "社群短影音介紹錄製乙則",
    "metaassets.service.content5-B-6-5": "25%的藝術品代銷費",
    "metaassets.service.content5-C-1": "150件作品與上架管理",
    "metaassets.service.content5-C-2": "專屬個人網頁",
    "metaassets.service.content5-C-3": "3D藝廊三間 (可舉辦3檔展覽)專屬藝術名片五盒及印刷檔",
    "metaassets.service.content5-C-4": "ART ON AIR 徵選資格",
    "metaassets.service.content5-C-5": "金流刷卡交易電子紀錄與證書服務(每筆交易15%手續費)",
    "metaassets.service.content5-C-6": "作品增加乙件＄150元",
    "metaassets.service.content5-C-7": "行銷曝光",
    "metaassets.service.content5-C-7-1": "波翼藝術社群曝光＋Maggie Art Talks",
    "metaassets.service.content5-C-7-2": "波翼官網BANNER露出三則",
    "metaassets.service.content5-C-7-3": "作品增加乙件＄150元",
    "metaassets.service.content5-C-7-4": "展覽新聞稿3則（保證露出3則媒體含YAHOO)",
    "metaassets.service.content5-C-7-5": "個人形象照專業拍攝兩張",
    "metaassets.service.content5-C-7-6": "社群短影音介紹錄製數則",
    "metaassets.service.content5-C-7-7": "25%的藝術品代銷費",
    "metaassets.service.title6": "更多展間實例",
    "metaassets.service.title7": "Q&A? 常見問題",
    "metaassets.service.content7-1-1": "1.什麼是METAASSETS藝術品資產管理認證?",
    "metaassets.service.content7-1-2": "ANS:我們是一個透過區塊鏈技術來服務當代藝術的服務，從藝術品的溯源到後續流通曝光及追蹤與鑑定，皆可以透過我們的藝術資產管理來解決。",
    "metaassets.service.content7-2-1": "2.為什麼需要作藝術品管理服務?",
    "metaassets.service.content7-2-2": "ANS: 讓藝術家可以專注於創作，因為經年創作下來的作品眾多，加上參加許多展覽與銷售，很多藝術品追蹤管理不易，以及藝術品很多沒機會展示，都擺在倉庫保存，無法被世人看見，故需要藝術品管理服務。",
    "metaassets.service.content7-3-1": "3.我的作品沒有製作高解析度數位檔案，要怎麼辦?",
    "metaassets.service.content7-3-2": "ANS: METAASSETS提供PHASE ONE專屬高解析度數位藝術品拍攝服務，可將藝術品送至波翼藝術中心或專人出機服務拍攝，每件作品拍攝後為1.5億畫數，並作為後續專利認證與AI辨識藝術品真偽使用。",
    "metaassets.service.content7-4-1": "4.我的作品製作高解析度數位檔案後，會不會被下載複製?",
    "metaassets.service.content7-4-2": "ANS : 藝術品高解析度檔案僅作為區塊鏈上憑證認證發行使用，提供於線上藝廊與資料庫網站上的作品會經過壓縮避免被盜後運用，但不影響藝術品賞析與體驗。",
    "metaassets.service.content7-5-1": "5.我可以只作藝術品認證管理，但不要上區塊鏈嗎?",
    "metaassets.service.content7-5-2": "ANS: 可以，所有藝術品上鏈與否都可由藝術家決定，上區塊鏈好處是藝術品認證溯源並無法竄改，但也可以選揭露交易價格或是隱藏交易價格。",
    "metaassets.service.content7-6-1": "6.如果我僅需要實體展覽空間可以嗎?",
    "metaassets.service.content7-6-2": "ANS: 可以，METAASSETS有跟波翼藝術中心專屬合作，提供所有合作藝術家/藝廊/藏家/協會實體展覽空間租賃合作。",
    "metaassets.service.content7-7-1": "7.我製作虛擬藝廊一定要掛在METAASSETS網站上嗎?",
    "metaassets.service.content7-7-2": "ANS: 不用，用戶可自行選擇是否顯示在METAASSETS認證資料庫網站，或是僅私人蒐藏與分享使用。",
    "metaassets.service.content7-8-1": "8.我的虛擬藝廊可以如何分享?",
    "metaassets.service.content7-8-2": "ANS: 虛擬藝廊建置後有專屬的網址，可以透過社群軟體分享，包含Line、FB、IG等社交軟體分享給親朋好友或是藏家，增加作品能見度與流通展示的機會。",
    "metaassets.service.content7-9-1": "9.我擔心虛擬藝廊無法呈現實體藝術品，所帶來的視覺感動",
    "metaassets.service.content7-9-2": "ANS:  放心！ 我們虛擬藝廊都可以設定與您聯繫約定看實體，讓欣賞藝術品時間更有彈性。",
    "metaassets.service.content7-10-1": "10.我銷售的藝術品，如何取得藝術資產憑證給購買的藏家?",
    "metaassets.service.content7-10-2": "ANS: 首先您必須申請MetaMask 狐狸錢包，目前藝術資產憑證取得的方式有兩種，一種是可以於虛擬藝廊收藏作品時，選擇加購藝術資產憑證，將會於完成交易時轉移至收藏者指定的MetaMask錢包，或是直接聯繫我們METAASSETS官方協助發行憑證後，轉移至指定MetaMask錢包。",
    "metaassets.service.content8-11-1": "ANS: 藝術資產流水號說明，每一件作品皆有專屬的的流水號，其代號說明如下:",
    "metaassets.service.content8-11-2": "ANS: FA 代表授權模式為全部授權(包含著作財產權)/PA 代表授權模式為部分授權(不包含著作財產權)/OW 代表授權模式為擁有權轉讓(單純藝術品轉移)",
    "metaassets.service.button": "我要服務",
    "metaassets.service.tel": "洽詢專線",
    "metaassets.tip.title": "Metaassets認證編號授權說明",
    "metaassets.tip.content1": "Lic-FA 代表授權模式為全部授權(包含著作財產權)",
    "metaassets.tip.content2": "Lic-PA 代表授權模式為部分授權(不包含著作財產權)",
    "metaassets.tip.content3": "Lic-OW 代表授權模式為擁有權轉讓(單純藝術品轉移)",


}
