import React, {useContext} from "react";
import {ModalDispatchContext, toggleModal} from "../contexts/modal";
import useI18n from '../hook/useI18n';

const Modal = ({show}) => {
    const modaldispatch = useContext(ModalDispatchContext);
    const {t} = useI18n();
    const handleModal = ()=>{
        return toggleModal(modaldispatch)
    };
    if(!show){
        return null;
    }

    return (
        <div className="modal" id="modal">
            <div className="actions">
                <button className="toggle-button" onClick={handleModal}>
                    ✖
                </button>
            </div>
            <div className="modalWrap">
            <div className="content">
                <section id="teaching_title">
                    <h2>{t("metaassets.tutorial.head")}</h2>
                    <p>{t("metaassets.tutorial.headContent")}</p>
                </section>

                <section id="teaching_step1">
                    <h2>Step1</h2>
                    <p>{t("metaassets.tutorial.step1")}</p>
                    <ul className="ul">
                        <li><a href="https://accounts.binance.com/zh-TW/register" target="_blank">Binance</a></li>
                        <li><a href="https://max.maicoin.com/signup" target="_blank">MAX</a></li>
                    </ul>
                </section>

                <section id="teaching_step2">
                    <h2>Step2</h2>
                    <p>{t("metaassets.tutorial.step2")}</p>
                    <p className="teaching_desc">{t("metaassets.tutorial.step2-2")}</p>
                    <ul className="ul">
                        <li><a href="https://metamask.io/" target="_blank">MetaMask</a></li>
                    </ul>
                </section>

                <section id="teaching_step3">
                    <h2>Step3</h2>
                    <p>{t("metaassets.tutorial.step3")}</p>
                    <ul className="ul">
                        <li><a href="https://t.me/+-69l96-LQgs0Mzk1" target="_blank">Metaassets</a></li>
                    </ul>
                </section>
            </div>
            </div>
        </div>
    )
};

export default Modal;