const NotFound = () => {
    return (
        <div>
            <main>
                <section id="error_404">
                    <h2>404 錯誤： Not Found</h2>
                    <p>很抱歉，您要找的頁面不存在！是否前往其他頁面瀏覽?或查看更多<a href="">《藝術家典藏NFT作品...》</a></p>
                </section>
            </main>
        </div>

    )
};

export default NotFound;