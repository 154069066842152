import logo from '../assets/img/logo.png';
import datalist from '../data/artworks1.json';
import proList from '../data/pro';
import coopList from '../data/coop';
import enpowerList from '../data/enpower';
import art_svg from '../assets/img/art.svg';
import coin_svg from '../assets/img/coin.svg';
import prove_svg from '../assets/img/prove.svg';
import useI18n from '../hook/useI18n';
import {useContext, useCallback} from "react";
import {I18nContext} from "../contexts/lang";
import {ModalDispatchContext, ModalStateContext} from "../contexts/modal";
import ViewImg from '../hook/viewImg';
import React, {useState, useRef} from "react";
import ViewFrame from "../hook/viewFrame";
import Modal from "../components/Modal";
import CertificationModal from "../components/CertificationModal";
import SellModal from "../components/SellModal";
import video from "../assets/video/banner_movie.mp4"
import ip_video from "../assets/video/certificate_movie.mp4"

const Home = () => {
    const [filp, setFilp] = useState(0);
    const [show, setShow] = useState(false);
    const [showSellModal, setShowSellModal] = useState(false);
    const [data, setData] = useState(null);
    const {t} = useI18n();
    const locale = useContext(I18nContext);
    const {isModalOpen} = useContext(ModalStateContext);
    const mounted = useRef(true);
    const artsList = useRef([]);
    const unreleaseList = useRef([]);


    const tmp_arts = datalist && datalist.filter((asset) => {

        return (asset.status === 1);
    });

    const tmp_unrelease = datalist && datalist.filter((asset) => {
        return asset.status ;
    });


    const shuffle = (array)=>{
        let i = 3,
            l = array.length,
            j = 0,
            tmp = [],
            rand = [];
        if(array.length){
            array.forEach(function (v){
                tmp.push(v);
            })

            while (i--) {
                j = Math.floor(Math.random() * l);

                rand.push(tmp[j]);
                tmp.splice(j,1);
                l --
            }
            return rand;
        }
        return [];
    }
    if (mounted.current) {

        mounted.current = false;
        artsList.current = shuffle(tmp_arts);
        unreleaseList.current = shuffle(tmp_unrelease);

    }

    const handleFilp = () => {

        setFilp(filp => filp + 1);
        mounted.current = true;
        artsList.current = [];
        unreleaseList.current = [];
    };

    const handleSellModal = (data) => {
        setShowSellModal(!showSellModal);
        setData(data)
    };

    const handlecloseSellModal = () => {
        setShowSellModal(false)
    };

    const handleModal = (data) => {
        setShow(!show);
        setData(data)
    };
    const handlecloseModal = () => {
        setShow(false)
    };

    const handPurchase = (data) =>{

        let msg = locale.getLocale() === 'zh-TW'?
            `確認離開目前網頁開啟${data.name}藝術品的專屬購買頁？`
            : `Leave current page and go to ${data.name_en} purchase page?`

        // eslint-disable-next-line no-restricted-globals
        if(confirm(msg)){
            window.location.href = `https://virtual.metaassets.asia/artworks/${data.certi_no}`
        }

        return false;
    }

    return (
        <div className="black_theme">
            {/*<Modal show={isModalOpen}/>*/}
            <SellModal show={showSellModal} data={data} handleClose={handlecloseSellModal}/>
            <CertificationModal show={show} data={data} handleClose={handlecloseModal}/>
            <div className="banner">
                <img src={logo} alt="logo"/>
                <div className="title">
                    <h1>{t('metaassets.home.head')}</h1>
                    <ViewFrame url="https://virtual.metaassets.asia/exhibitions/DEMO" name={<button
                        className="btn_fill line">{t('metaassets.home.virtual')}&nbsp;&#10148;</button>}/>

                </div>
                <div className="banner_video_wrap">
                    <video
                        playsInline
                        loop
                        autoPlay
                        muted
                        id="banner_video">
                        <source src={video} type="video/mp4"/>
                    </video>
                </div>
            </div>
            <main>
                <div className="slide promise">
                    <div className="item">
                        <div className="icon_wrap">
                            <img className="icon_img" src={coin_svg}/>
                        </div>
                        <h3>{t('metaassets.banner.promise1')}</h3>
                    </div>
                    <div className="item">
                        <div className="icon_wrap">
                            <img className="icon_img" src={art_svg}/>
                        </div>
                        <h3>{t('metaassets.banner.promise2')}</h3>
                    </div>
                    <div className="item">
                        <div className="icon_wrap">
                            <img className="icon_img" src={prove_svg}/>
                        </div>
                        <h3>{t('metaassets.banner.promise3')}</h3>
                    </div>
                </div>
                <section id="nfts">
                    <h2>{t('metaassets.home.invest')}</h2>
                    <p>{t('metaassets.home.investOfContent')}</p>
                </section>

                <section id="art">
                    <h2>{t('metaassets.home.art')}</h2>
                    <button className="btn_more" onClick={handleFilp}>{t('metaassets.home.more')}</button>
                </section>
                <div className="slide">
                    {
                        artsList.current.map((data) => {

                            // let purchase = data.entity?  <h3 className="purchasing">
                            //         <a target="_blank" href={`https://virtual.metaassets.asia/artworks/${data.certi_no}`}>
                            //         {t('metaassets.home.sell')}
                            //         </a>
                            //         </h3>:
                            //     "";
                            let media = data.medias[0]? `(${data.medias[0]})`: '';
                            let media_en = data.medias_en[0]? `(${data.medias_en[0]})`: '';
                            return <div className="item" key={data.id}>
                                <img className="head_img" src={require('../assets/img/light.png')}/>

                                <div><ViewImg src={data.url}
                                              alt={locale.getLocale() === 'zh-TW' ? data.name : data.name_en}/></div>
                                <h3>{locale.getLocale() === 'zh-TW' ? `${data.name}` : `${data.name_en}`}</h3>
                                {/*<h3><ViewFrame url={data.pinpinbox}*/}
                                {/*               name={locale.getLocale() === 'zh-TW' ? data.creator : data.creator_en}/>*/}
                                {/*</h3>*/}
                                <h3>{locale.getLocale() === 'zh-TW' ? data.creator : data.creator_en}</h3>
                                <h3>{locale.getLocale() === 'zh-TW' ? `${data.category}${media}` : `${data.category_en}${media_en}`}</h3>
                                <h3>{t('metaassets.home.qty')}{data.qty}</h3>
                                <h3 className="certificated_no" onClick={() => handleModal(data)}>{data.certi_no}</h3>
                                {/*{purchase}*/}
                            </div>
                        })
                    }
                </div>



                <section id="ip">
                    <h2>{t('metaassets.home.ip')}</h2>
                    <button className="btn_more" onClick={handleFilp}>{t('metaassets.home.more')}</button>

                </section>
                <div className="slide">
                    {
                        unreleaseList.current.map((data) => {

                            let purchase = data.entity?
                                <h3 className="purchasing">
                                    <a target="_blank" href={`https://virtual.metaassets.asia/artworks/${data.certi_no}`}>
                                    {t('metaassets.home.sell')}
                                    </a>
                                 </h3>:
                                "";
                            let media = data.medias[0]? `(${data.medias[0]})`: '';
                            let media_en = data.medias_en[0]? `(${data.medias_en[0]})`: '';
                            return <div className="item" key={data.id}>
                                <img className="head_img" src={require('../assets/img/light.png')}/>
                                <div><ViewImg src={data.url}
                                              alt={locale.getLocale() === 'zh-TW' ? data.name : data.name_en}/></div>
                                <h3>{locale.getLocale() === 'zh-TW' ? `${data.name}` : `${data.name_en}`}</h3>
                                {/*<h3><ViewFrame url={data.pinpinbox}*/}
                                {/*               name={locale.getLocale() === 'zh-TW' ? data.creator : data.creator_en}/>*/}
                                {/*</h3>*/}
                                <h3>{locale.getLocale() === 'zh-TW' ? data.creator : data.creator_en}</h3>
                                <h3>{locale.getLocale() === 'zh-TW' ? `${data.category}${media}` : `${data.category_en}${media_en}`}</h3>
                                <h3>{t('metaassets.home.qty')} {data.qty}</h3>
                                <h3 className="certificated_no" onClick={() => handleModal(data)}>{data.certi_no}</h3>
                                {purchase}
                            </div>
                        })
                    }
                </div>

                <section id="advantage">
                    <h2>{t('metaassets.block.tilte')}</h2>
                </section>

                <div className="flex-table flex-table-one">

                    <div className="item">

                        <div className="pro_video_wrap">
                            <iframe id="advantage_video" src="https://www.youtube.com/embed/oLfoM5hLzmA"
                                    title="Metaassets 藝術資產憑證介紹" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                    allowFullScreen></iframe>
                        </div>
                    </div>

                </div>
                <div className="flex-table flex-table-enpower advantage_bg">
                    <div className="item">
                        <div className="pro_list">
                            <h3>{t('metaassets.block.item1')}</h3>
                            <h4></h4>
                        </div>

                    </div>
                    <div className="item">
                        <div className="pro_list">
                            <h3>{t('metaassets.block.item2')}</h3>
                            <h4></h4>
                        </div>
                    </div>
                    <div className="item">
                        <div className="pro_list">
                            <h3>{t('metaassets.block.item3')}</h3>
                            <h4></h4>
                        </div>
                    </div>
                    <div className="item">
                        <div className="pro_list">
                            <h3>{t('metaassets.block.item4')}</h3>
                            <h4></h4>
                        </div>
                    </div>
                    <div className="item">
                        <div className="pro_list">
                            <h3>{t('metaassets.block.item5')}</h3>
                            <h4></h4>
                        </div>
                    </div>
                </div>

                {/*<section id="enpower">*/}
                {/*    <h2>{t('metaassets.home.enpower')}</h2>*/}
                {/*</section>*/}
                {/*<div className="flex-table flex-table-enpower">*/}
                {/*    {*/}
                {/*        enpowerList.map((data) => {*/}
                {/*            return <div className="item" key={data.id}>*/}
                {/*                <div className="pro_img_wrap">*/}
                {/*                    <img className="pro_img" src={require(`../assets/img/${data.img}`)}/>*/}
                {/*                </div>*/}
                {/*                <div className="pro_list">*/}
                {/*                    <h3>{locale.getLocale() === 'zh-TW' ? data.name : (locale.getLocale() === 'en-US') ? data.name_en : data.name_jpn}</h3>*/}
                {/*                    {locale.getLocale() === 'zh-TW' ? data.list.map((h, k) => {*/}
                {/*                        return <h4 key={k}>*/}
                {/*                            {h}<br/>*/}
                {/*                        </h4>*/}
                {/*                    }) : (locale.getLocale() === 'en-US') ?*/}
                {/*                        data.list_en.map((h, k) => {*/}
                {/*                            return <h4 key={k}>*/}
                {/*                                {h}<br/>*/}
                {/*                            </h4>*/}
                {/*                        })*/}
                {/*                        :*/}
                {/*                        data.list_jp.map((h, k) => {*/}
                {/*                            return <h4 key={k}>*/}
                {/*                                {h}<br/>*/}
                {/*                            </h4>*/}
                {/*                        })*/}
                {/*                    }*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        })*/}
                {/*    }*/}
                {/*</div>*/}

                <section id="about">
                    <h2>{t('metaassets.home.about')}</h2>
                    <p>{t('metaassets.home.aboutOfContent')}</p>
                </section>

                <section id="service">
                    <h2>{t('metaassets.home.service')}</h2>
                    <ul className="ul">
                        <li>{t('metaassets.home.service_item1')}</li>
                        <li>{t('metaassets.home.service_item2')}</li>
                        <li>{t('metaassets.home.service_item3')}</li>
                        <li>{t('metaassets.home.service_item4')}</li>
                    </ul>
                </section>


                <section id="team">
                    <h2>{t('metaassets.home.team')}</h2>
                </section>

                <div className="flex-table flex-table-one">
                    {
                        proList.map((data) => {
                            return <div className="item" key={data.id}>
                                <div className="pro_img_wrap">
                                    <img className="pro_img" src={require(`../assets/img/${data.img}`)}/>
                                </div>
                                <div className="pro_list">
                                    <h3>{locale.getLocale() === 'zh-TW' ? data.name : (locale.getLocale() === 'en-US' ? data.name_en : data.name_jp)}</h3>
                                    {locale.getLocale() === 'zh-TW' ? data.history.map((h, k) => {
                                        return <h4 key={k}>
                                            {h}<br/>
                                        </h4>
                                    }) : (locale.getLocale() === 'en-US') ?
                                        data.history_en.map((h, k) => {
                                            return <h4 key={k}>
                                                {h}<br/>
                                            </h4>
                                        }) :
                                        data.history_jp.map((h, k) => {
                                            return <h4 key={k}>
                                                {h}<br/>
                                            </h4>
                                        })
                                    }
                                </div>
                            </div>
                        })
                    }
                </div>

                <section id="cooperation">
                    <h2>{t('metaassets.coop.head')}</h2>
                </section>
                <div className="flex-table flex-table-cooperation">
                    {
                        coopList.map((data) => {
                            return <div className="item logo_shangsun" key={data.id}>
                                <div className="pro_img_wrap">
                                    <img className="pro_img" src={require(`../assets/img/${data.img}`)}/>
                                </div>
                                <div className="pro_list">
                                    <h3><a href={data.src}
                                           target="_blank">{locale.getLocale() === 'zh-TW' ? data.name : (locale.getLocale() === 'en-US') ? data.name_en : data.name_jp}</a>
                                    </h3>
                                </div>
                            </div>
                        })
                    }
                </div>
            </main>
            <div className="banner banner2">
                <div className="title">
                    <img src={require('../assets/img/logo_white.png')} alt="logo"/>
                    <h3>{t('metaassets.home.bottom')}</h3>
                    <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSdK6jpzvxuUHrFXFDXzhwg2sP6MBg3YAV84KY-JsSmJjCVvig/viewform?pli=1">
                        <button className="btn_fill">&#x2611;YES，{t('metaassets.home.bottomOfLink')}</button>
                    </a>
                </div>
            </div>
        </div>
)
};

export default Home;