import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import CommonLayout from './layouts/CommonLayout';
import SerachProvider from './contexts/serach';
import ModalProvider from './contexts/modal';
import I18nProvider from './contexts/lang';
import HomePage from './pages/home';
import ServicePage from './pages/service';
import AssetsPage from './pages/assets';
import NotFound from './pages/notFound';
import './assets/scss/style.scss';

function App() {


    const locales = ['en-US', 'zh-TW', 'ja-JP'];
    const translations = {
        'en-US': require('./locales/en-US').default,
        'zh-TW': require('./locales/zh-TW').default,
        'ja-JP': require('./locales/ja-JP').default,
    };
    return (
        <ModalProvider>
            <I18nProvider locales={locales} translations={translations}>
                <SerachProvider>
                    <Router>
                        <CommonLayout>
                            <Routes>
                                <Route path="*" element={<NotFound/>}/>
                                <Route exact path="/" element={<HomePage/>}/>
                                <Route exact path="/assets" element={<AssetsPage/>}/>
                                <Route exact path="/service" element={<ServicePage/>}/>
                            </Routes>
                        </CommonLayout>
                    </Router>
                </SerachProvider>
            </I18nProvider>
        </ModalProvider>
    )
}

export default App;
