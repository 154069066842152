const ViewFrame = ({url, name}) => {

    const process = (e)=>{
        e.preventDefault();

        zoomIn().then(()=>{

            let iFrame = document.getElementById('overlayFrame');
            //遮蓋X
            if (iFrame.src.indexOf('pinpinbox') != -1)

                document.getElementById('overlay').className += ' ' + 'coverX';
        })
    };


    const zoomIn = () => {

        return new Promise(function (resolve, reject) {

            let elem = document.createElement('div');
            elem.setAttribute('id', 'overlay');
            elem.className = 'overlay';
            elem.innerHTML = '<span class="overlayClose" onclick="document.getElementById(\'overlay\').remove();">&#10006;</span>' +
                '<div class="overlayWrap">' +
                '<div class="overlayContent">' +
                '<iframe id="overlayFrame" class="overlayiFrame" src="' + url + '"' +
                ' onload="" > " ' +
                '>Unfound.</iframe> ' +
                '</div>' +
                '</div>';
            document.body.appendChild(elem);

            resolve();
        });
    };

    return (
        <a href="#" onClick={process}>
            {name}
        </a>
    );

};

export default ViewFrame;