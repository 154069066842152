import React, { useReducer, createContext} from "react";

const initialState = {
    isSearchBarOpen: false,
    searchKeyword: ""
};

export const SerachStateContext = createContext();
export const SerachDispatchContext = createContext();

const reducer = (state, action) => {
    switch (action.type) {
        case "TOGGLE_SEARCH_BAR":
            return {
                ...state,
                isSearchBarOpen: !state.isSearchBarOpen
            };
        case "SET_SEARCH_KEYWORD":
            return {
                ...state,
                searchKeyword: action.payload.searchKeyword
            };
        default:
            throw new Error(`Unknown action: ${action.type}`);
    }
};

const SerachProvider = ({children})=>{
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <SerachDispatchContext.Provider value={dispatch}>
            <SerachStateContext.Provider value={state}>
                {children}
            </SerachStateContext.Provider>
        </SerachDispatchContext.Provider>
    );
};

export const toggleSearchBar = (dispatch) => {
    return dispatch({
        type: "TOGGLE_SEARCH_BAR"
    });
};

export const setSearchKeyword = (dispatch, searchKeyword) => {
    return dispatch({
        type: "SET_SEARCH_KEYWORD",
        payload: {
            searchKeyword: searchKeyword
        }
    });
};

export default SerachProvider;