import React, {useEffect, useState, useContext, useRef} from "react";

import {I18nContext} from '../contexts/lang';
import useI18n from '../hook/useI18n';
import service_p_01 from '../assets/img/service_p_01.png';
import service_p_02 from '../assets/img/service_p_02.png';
import service_p_03 from '../assets/img/service_p_03.png';
import service_p_04 from '../assets/img/service_p_04.png';
import service_p_05 from '../assets/img/service_p_05.png';
import service_p_06 from '../assets/img/service_p_06.png';
import service_f_01 from '../assets/img/service_f_01.png';
import service_f_02 from '../assets/img/service_f_02.png';
import service_f_03 from '../assets/img/service_f_03.png';
import service_f_04 from '../assets/img/service_f_04.png';
import service_t_bg_01 from '../assets/img/service_t_bg_01.png';
import pdf from '../assets/pdf/專利證書(129153-TW-PA-U).pdf';
import pdf2 from '../assets/pdf/專利證書(131726-TW-PA-U).pdf';
import pdf3 from '../assets/pdf/20240620-專利證書(131726-JP-PA-U).pdf';

const Service = () => {

    const locale = useContext(I18nContext);
    const {t} = useI18n();
    const [video, setVideo] = useState(' https://www.youtube.com/embed/5wFdemN5PP0');

    useEffect(() => {
        async function updateSrc() {
            await locale.getLocale() === 'zh-TW' ? setVideo('https://www.youtube.com/embed/TcoNAwhwWoU') :
                setVideo('https://www.youtube.com/embed/5wFdemN5PP0');
            // await videoRef.current?.load();
        }

        updateSrc();


    }, [locale.getLocale()])


    const handleArtistUrl = async () => {
        await locale.getLocale() === 'zh-TW' ?
            setVideo('https://www.youtube.com/embed/TcoNAwhwWoU') :
            setVideo('https://www.youtube.com/embed/5wFdemN5PP0');
        // await videoRef.current?.load();
        // await videoRef.current?.play();
    }

    const handleCollectorUrl = async () => {
        await locale.getLocale() === 'zh-TW' ?
            setVideo('https://www.youtube.com/embed/hm9P6kSwxa4') :
            setVideo('https://www.youtube.com/embed/t2GSzBAxzis');
        // await videoRef.current?.load();
        // await videoRef.current?.play();
    }

    return (
        <>
            <div className="black_theme">
                <div className="banner banner_service">

                </div>
                <main>
                    <section id="service_m">

                        <h2>{t("metaassets.service.title1")}</h2>
                        <h3>{t("metaassets.service.title1-1")}</h3>
                        <p className="sub_title">{t("metaassets.service.content1")}</p>
                        <p>{t("metaassets.service.content1-1")}</p>

                    </section>
                    <section id="service_ac">

                        <div className="flex-table service_ac">
                            <div className="item">
                                <div className="pro_list">
                                    <h3>
                                        <input onClick={handleArtistUrl} type="radio" name="tabRadio" id="tabRadio01"
                                               />
                                        <label htmlFor="tabRadio01">
                                            <div className="arrow"><span></span><span></span><span></span></div>
                                            <span>{t("metaassets.service.playbutton-artist")}</span>
                                        </label>
                                    </h3>
                                    <h3>
                                        <input onClick={handleCollectorUrl} type="radio" name="tabRadio"
                                               id="tabRadio02"/>
                                        <label htmlFor="tabRadio02">
                                            <div className="arrow"><span></span><span></span><span></span></div>
                                            <span>{t("metaassets.service.playbutton-collect")}</span>
                                        </label>

                                    </h3>
                                </div>
                                <div className="pro_video_wrap">

                                    <iframe src={video}
                                            title="Metaassets 藝術品管理服務" frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            allowFullScreen></iframe>


                                    {/*<video ref={videoRef} muted playsInline controls id="service_ac_video_01">*/}
                                    {/*    <source src={video} type="video/mp4"/>*/}
                                    {/*</video>*/}
                                </div>
                            </div>
                        </div>

                    </section>

                    <section id="service_p">
                        <h2>{t("metaassets.service.title2")}</h2>
                    </section>

                    <div className="flex-table flex-table-service_p">
                        <div className="item">
                            <div className="pro_img_wrap">
                                <img className="pro_img" src={service_p_01}/>
                            </div>
                            <div className="pro_list">
                                <h3>1</h3>
                                <h4>{t("metaassets.service.content2-1")}</h4>
                            </div>

                        </div>
                        <div className="item">
                            <div className="pro_img_wrap">
                                <img className="pro_img" src={service_p_02}/>
                            </div>
                            <div className="pro_list">
                                <h3>2</h3>
                                <h4>{t("metaassets.service.content2-2")}</h4>
                            </div>
                        </div>
                        <div className="item">
                            <div className="pro_img_wrap">
                                <img className="pro_img" src={service_p_03}/>
                            </div>
                            <div className="pro_list">
                                <h3>3</h3>
                                <h4>{t("metaassets.service.content2-3")}</h4>
                            </div>
                        </div>

                    </div>

                    <div className="flex-table flex-table-service_p">
                        <div className="item">
                            <div className="pro_img_wrap">
                                <img className="pro_img" src={service_p_06}/>
                            </div>
                            <div className="pro_list">
                                <h3>6</h3>
                                <h4>{t("metaassets.service.content2-6")}</h4>
                            </div>
                        </div>
                        <div className="item">
                            <div className="pro_img_wrap">
                                <img className="pro_img" src={service_p_05}/>
                            </div>
                            <div className="pro_list">
                                <h3>5</h3>
                                <h4>{t("metaassets.service.content2-5")}</h4>
                            </div>
                        </div>
                        <div className="item">
                            <div className="pro_img_wrap">
                                <img className="pro_img" src={service_p_04}/>
                            </div>
                            <div className="pro_list">
                                <h3>4</h3>
                                <h4>{t("metaassets.service.content2-4")}</h4>
                            </div>
                        </div>
                    </div>

                    <section id="service_f">
                        <h2>{t("metaassets.service.title3")}</h2>
                        <h3>{t("metaassets.service.title3-1")}</h3>
                    </section>

                    <div className="flex-table flex-table-service_f">
                        <div className="item">
                            <div className="pro_img_wrap">
                                <img className="pro_img" src={service_f_01}/>
                            </div>
                            <div className="pro_list">
                                <h4>{t("metaassets.service.content3")}</h4>
                            </div>

                        </div>
                        <div className="item">
                            <div className="pro_img_wrap">
                                <img className="pro_img" src={service_f_02}/>
                            </div>
                            <div className="pro_list">
                                <h4>{t("metaassets.service.content3-1")}</h4>
                            </div>
                        </div>
                        <div className="item">
                            <div className="pro_img_wrap">
                                <img className="pro_img" src={service_f_03}/>
                            </div>
                            <div className="pro_list">
                                <h4>{t("metaassets.service.content3-2")}</h4>
                            </div>
                        </div>
                        <div className="item">
                            <div className="pro_img_wrap">
                                <img className="pro_img" src={service_f_04}/>
                            </div>
                            <div className="pro_list">
                                <h4>{t("metaassets.service.content3-3")}</h4>
                            </div>
                        </div>
                    </div>
                    <section id="service_t">
                        <h2>{t("metaassets.service.title4")}</h2>
                        <h3>{t("metaassets.service.title4-1")}</h3>
                    </section>

                    <div className="flex-table flex-table-service_t">
                        <div className="item">

                            <div className="pro_list">
                                <h3>{t("metaassets.service.conetent4-1")}</h3>
                                <h4>{t("metaassets.service.conetent4-1-1")}
                                    <a target="_blank" href={pdf}>新型第M642339號-藝術品的防偽查驗系統</a>.
                                    <a target="_blank" href={pdf2}>新型第M643849號-藝術品的認證系統</a>
                                </h4>
                                <h4>
                                    日本專利
                                    <a target="_blank" href={pdf3}>新型第M646868號-藝術品的認證系統</a>
                                </h4>
                            </div>

                        </div>
                        <div className="item">

                            <div className="pro_list">
                                <h3>{t("metaassets.service.conetent4-2")}</h3>
                                <h4>{t("metaassets.service.conetent4-2-1")}</h4>
                            </div>
                        </div>
                        <div className="item">

                            <div className="pro_list">
                                <h3>{t("metaassets.service.conetent4-3")}</h3>
                                <h4>{t("metaassets.service.conetent4-3-1")}</h4>
                            </div>
                        </div>


                        <div className="item">
                            <div className="pro_img_wrap">
                                <img className="pro_img" src={service_t_bg_01}/>
                            </div>

                        </div>

                    </div>

                    <section id="service_c">
                        <h2>{t("metaassets.service.title5")}</h2>

                    </section>
                    <div className="flex-table flex-table-service_c">

                        <div className="item a">
                            <div className="pro_list">
                                <h2><span>A</span><span>{t("metaassets.service.content5")}</span></h2>
                                <h3><span className="currency">NTD</span><span className="money">$19,800</span><span
                                    className="unit">{t("metaassets.service.content5-common")}</span></h3>
                                <h4><span>{t('metaassets.service.content5-common-A')}</span><span></span></h4>

                                <ul className="ul">
                                    <li><span>{t('metaassets.service.content5-A-1')}</span></li>
                                    <li><span>{t('metaassets.service.content5-A-2')}</span></li>
                                    <li><span>{t('metaassets.service.content5-A-3')}</span></li>
                                    <li><span>{t('metaassets.service.content5-A-4')}</span></li>
                                    <li><span>{t('metaassets.service.content5-A-5')}</span></li>
                                    <li><span>{t('metaassets.service.content5-A-6')}</span></li>
                                    <li><span className="marketing">{t('metaassets.service.content5-A-7')}</span>
                                        <ul className="ul">
                                            <li><span>{t('metaassets.service.content5-A-7-1')}</span></li>
                                            <li><span>{t('metaassets.service.content5-A-7-2')}</span></li>
                                            <li><span>{t('metaassets.service.content5-A-7-3')}</span></li>
                                        </ul>
                                    </li>
                                    <li><span className="highlight">{t('metaassets.service.content5-A-8')}</span>
                                    </li>
                                </ul>
                                <a href="https://docs.google.com/forms/d/e/1FAIpQLSdK6jpzvxuUHrFXFDXzhwg2sP6MBg3YAV84KY-JsSmJjCVvig/viewform?pli=1">
                                    <button className="btn_more">{t('metaassets.service.content5-common-3')}</button>
                                </a>
                            </div>
                        </div>


                        <div className="item b">
                            <div className="pro_list">
                                <h2><span>B</span><span>{t("metaassets.service.content5")}</span></h2>
                                <h3><span className="currency">NTD</span><span className="money">$55,800</span><span
                                    className="unit">{t("metaassets.service.content5-common")}</span></h3>
                                <h4><span>{t('metaassets.service.content5-common-B')}</span><span></span></h4>

                                <ul className="ul">
                                    <li><span>{t('metaassets.service.content5-B-1')}</span></li>
                                    <li><span>{t('metaassets.service.content5-B-2')}</span></li>
                                    <li><span>{t('metaassets.service.content5-B-3')}</span></li>
                                    <li><span>{t('metaassets.service.content5-B-4')}</span></li>
                                    <li><span>{t('metaassets.service.content5-B-5')}</span></li>
                                    <li><span className="marketing">{t('metaassets.service.content5-B-6')}</span>
                                        <ul className="ul">
                                            <li><span>{t('metaassets.service.content5-B-6-1')}</span></li>
                                            <li><span>{t('metaassets.service.content5-B-6-2')}</span></li>
                                            <li><span className="highlight">{t('metaassets.service.content5-B-6-3')}</span></li>
                                            <li><span className="highlight">{t('metaassets.service.content5-B-6-4')}</span></li>
                                            <li><span>{t('metaassets.service.content5-B-6-5')}</span></li>
                                        </ul>
                                    </li>
                                </ul>
                                <a href="https://docs.google.com/forms/d/e/1FAIpQLSdK6jpzvxuUHrFXFDXzhwg2sP6MBg3YAV84KY-JsSmJjCVvig/viewform?pli=1">
                                    <button className="btn_more">{t('metaassets.service.content5-common-3')}</button>
                                </a>
                            </div>
                        </div>

                        <div className="item c">
                            <div className="pro_list">
                                <h2><span>C</span><span>{t("metaassets.service.content5")}</span></h2>
                                <h3><span className="currency">NTD</span><span className="money">$199,000</span><span
                                    className="unit">{t("metaassets.service.content5-common")}</span></h3>
                                <h4><span>{t('metaassets.service.content5-common-C')}</span><span></span></h4>

                                <ul className="ul">
                                    <li><span>{t('metaassets.service.content5-C-1')}</span></li>
                                    <li><span>{t('metaassets.service.content5-C-2')}</span></li>
                                    <li><span>{t('metaassets.service.content5-C-3')}</span></li>
                                    <li><span>{t('metaassets.service.content5-C-4')}</span></li>
                                    <li><span>{t('metaassets.service.content5-C-5')}</span></li>
                                    <li><span>{t('metaassets.service.content5-C-6')}</span></li>
                                    <li><span className="marketing">{t('metaassets.service.content5-C-7')}</span>
                                        <ul className="ul">
                                            <li><span className="highlight">{t('metaassets.service.content5-C-7-1')}</span>
                                            </li>
                                            <li><span>{t('metaassets.service.content5-C-7-2')}</span></li>
                                            <li><span>{t('metaassets.service.content5-C-7-3')}</span></li>
                                            <li><span className="highlight">{t('metaassets.service.content5-C-7-4')}</span></li>
                                            <li><span className="highlight">{t('metaassets.service.content5-C-7-5')}</span></li>
                                            <li><span>{t('metaassets.service.content5-C-7-6')}</span></li>
                                            <li><span>{t('metaassets.service.content5-C-7-7')}</span></li>
                                        </ul>
                                    </li>
                                </ul>
                                <a href="https://docs.google.com/forms/d/e/1FAIpQLSdK6jpzvxuUHrFXFDXzhwg2sP6MBg3YAV84KY-JsSmJjCVvig/viewform?pli=1">
                                    <button className="btn_more">{t('metaassets.service.content5-common-3')}</button>
                                </a>
                            </div>

                        </div>

                    </div>
                    {/*<div className="flex-table flex-table-service_c">*/}
                    {/*    <div className="item">*/}
                    {/*        <div className="pro_list">*/}
                    {/*            <h2><span>1</span><span>{t("metaassets.service.content5")}</span></h2>*/}
                    {/*            <h3><span className="currency">NTD</span><span className="money">$1,500</span><span*/}
                    {/*                className="unit">{t("metaassets.service.content5-common")}</span></h3>*/}
                    {/*            <h4><span>{t("metaassets.service.content5-1-1")}</span><span>{t("metaassets.service.content5-common-1")}</span></h4>*/}

                    {/*            <ul className="ul">*/}

                    {/*                <li><span>{t("metaassets.service.content5-1-2")}</span></li>*/}
                    {/*                <li><span>{t("metaassets.service.content5-common-2")} </span></li>*/}
                    {/*            </ul>*/}
                    {/*            <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSdK6jpzvxuUHrFXFDXzhwg2sP6MBg3YAV84KY-JsSmJjCVvig/viewform?pli=1">*/}
                    {/*                <button className="btn_more">{t("metaassets.service.content5-common-3")}</button>*/}
                    {/*            </a>*/}
                    {/*        </div>*/}

                    {/*    </div>*/}


                    {/*    <div className="item">*/}
                    {/*        <div className="pro_list">*/}
                    {/*            <h2><span>2</span><span>{t("metaassets.service.content5")}</span></h2>*/}
                    {/*            <h3><span className="currency">NTD</span><span className="money">$3,000</span><span*/}
                    {/*                className="unit">{t("metaassets.service.content5-common")}</span></h3>*/}
                    {/*            <h4><span>{t("metaassets.service.content5-2-1")}</span><span>{t("metaassets.service.content5-common-1")}</span></h4>*/}

                    {/*            <ul className="ul">*/}

                    {/*                <li><span>{t("metaassets.service.content5-2-2")}</span></li>*/}
                    {/*                <li><span>{t("metaassets.service.content5-common-2")}</span></li>*/}
                    {/*            </ul>*/}
                    {/*            <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSdK6jpzvxuUHrFXFDXzhwg2sP6MBg3YAV84KY-JsSmJjCVvig/viewform?pli=1">*/}
                    {/*                <button className="btn_more">{t("metaassets.service.content5-common-3")}</button>*/}
                    {/*            </a>*/}
                    {/*        </div>*/}

                    {/*    </div>*/}

                    {/*    <div className="item">*/}
                    {/*        <div className="pro_list">*/}
                    {/*            <h2><span>3</span><span>{t("metaassets.service.content5")}</span></h2>*/}
                    {/*            <h3><span className="currency">NTD</span><span className="money">$4,500</span><span*/}
                    {/*                className="unit">{t("metaassets.service.content5-common")}</span></h3>*/}
                    {/*            <h4><span>{t("metaassets.service.content5-3-1")}</span><span>{t("metaassets.service.content5-common-1")}</span></h4>*/}

                    {/*            <ul className="ul">*/}

                    {/*                <li><span>{t("metaassets.service.content5-3-2")}</span></li>*/}
                    {/*                <li><span>{t("metaassets.service.content5-common-2")}</span></li>*/}
                    {/*            </ul>*/}
                    {/*            <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSdK6jpzvxuUHrFXFDXzhwg2sP6MBg3YAV84KY-JsSmJjCVvig/viewform?pli=1">*/}
                    {/*                <button className="btn_more">{t("metaassets.service.content5-common-3")}</button>*/}
                    {/*            </a>*/}
                    {/*        </div>*/}

                    {/*    </div>*/}
                    {/*</div>*/}

                    <section id="service_v">
                        <a target="_blank" href="https://www.postwings.art/verx">
                            <button className="btn_more">{t("metaassets.service.title6")}</button>
                        </a>
                    </section>

                    <section id="service_q">
                        <h2>{t("metaassets.service.title7")}</h2>
                        <ol className="ul">
                            <li><span>{t("metaassets.service.content7-1-1")}</span>
                                <span>{t("metaassets.service.content7-1-2")}</span>
                            </li>

                            <li><span>{t("metaassets.service.content7-2-1")}</span>
                                <span>{t("metaassets.service.content7-2-2")}</span>
                            </li>

                            <li><span>{t("metaassets.service.content7-3-1")}</span>
                                <span>{t("metaassets.service.content7-3-2")}</span>
                            </li>

                            <li><span>{t("metaassets.service.content7-4-1")}</span>
                                <span>{t("metaassets.service.content7-4-2")}</span>
                            </li>


                            <li><span>{t("metaassets.service.content7-5-1")}</span>
                                <span>{t("metaassets.service.content7-5-2")}
</span>
                            </li>

                            <li><span>{t("metaassets.service.content7-6-1")}</span>
                                <span>{t("metaassets.service.content7-6-2")}</span>
                            </li>


                            <li><span>{t("metaassets.service.content7-7-1")}</span>
                                <span>{t("metaassets.service.content7-7-2")}</span>
                            </li>

                            <li><span>{t("metaassets.service.content7-8-1")}</span>
                                <span>{t("metaassets.service.content7-8-2")}</span>
                            </li>

                            <li><span>{t("metaassets.service.content7-9-1")}</span>
                                <span>{t("metaassets.service.content7-9-2")}</span>
                            </li>

                            <li><span>{t("metaassets.service.content7-10-1")}</span>
                                <span>{t("metaassets.service.content7-10-2")}</span>
                            </li>

                            <li><span>{t("metaassets.service.content8-11-1")}</span>
                                <span>{t("metaassets.service.content8-11-2")}</span>
                            </li>

                        </ol>
                    </section>

                    <section id="service_s">
                        {/*<a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSdK6jpzvxuUHrFXFDXzhwg2sP6MBg3YAV84KY-JsSmJjCVvig/viewform?pli=1">*/}
                        {/*    <button className="btn_more">更多其他問題</button>*/}
                        {/*</a>*/}
                        <p>
                            <span>{t("metaassets.service.tel")}</span>
                            <span>
                                <a href="tel:+886225014322">+886-2-25014322</a>
                            </span>
                        </p>
                    </section>
                </main>
            </div>
        </>
    )
}

export default Service;