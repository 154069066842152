export default {
    "metaassets.head.about": "About Metaassets",
    "metaassets.head.art": "Art Collection",
    "metaassets.head.ip": "Recommend favorites",
    "metaassets.head.asset": "Asset search",
    "metaassets.head.tutorial": "investment teaching",
    "metaassets.head.content": "Invest in your future world assets",
    "metaassets.head.link": "Virtual reality show",
    "metaassets.head.serach": "more artwork",
    "metaassets.banner.promise1": "Art Asset Certificate Issuance",
    "metaassets.banner.promise2": "Patent Counterfeit Inspection Technology",
    "metaassets.banner.promise3": "Art Genealogy Inquiry Database",
    "metaassets.home.head": "Invest in your future world assets",
    "metaassets.home.virtual": "Virtual reality show",
    "metaassets.home.invest": "Modern Art  New blockchain application",
    "metaassets.home.investOfContent": "Art asset certificates are issued using blockchain technology, providing unique identities using the reliable, non-tamper features of NFTs to issue digital asset certificates. Metaassets is a provider of certification for the sourcing of contemporary art digital assets, preventing distribution infringement by introducing concepts of physical art and asset value to the digital world through its certificates, breaking new ground for precious works of contemporary art and providing investors and collectors with legally guaranteed art asset certificates, safeguarding ownership rights to physical and digital artwork.",
    "metaassets.home.art": "Art collection",
    "metaassets.home.qty": "Mint: ",
    "metaassets.home.sell": "Favorite",
    "metaassets.home.position": "position",
    "metaassets.home.ip": "Recommend favorites",
    "metaassets.home.enpower": "Investment Enablement Program",
    "metaassets.home.about": "About Metaassets",
    "metaassets.home.aboutOfContent": "About Metaassets\n" +
        "The Metaassets team uses blockchain technology to assist the contemporary art world in creating a Web 3.0 ecological application for contemporary art. The virtual and real integrated art asset certificate issuance service allows contemporary art artists to quickly create their own contemporary art meta verse, through online virtual and real integrated exhibition services, expanding the exposure time and breadth of contemporary artworks. It is not affected by regions, and can also  conform with international standards, creating a borderless contemporary art exhibition service.",
    "metaassets.home.service": "Service items",
    "metaassets.home.service_item1": "Mint NFT source certification",
    "metaassets.home.service_item2": "Virtual reality show",
    "metaassets.home.service_item3": "Art Collection Payment Gateway (Credit Card) Setup Service",
    "metaassets.home.service_item4": "Art Inspection Database",
    "metaassets.home.team": "Law Certified",
    "metaassets.home.bottom": "Invest in your future world assets",
    "metaassets.home.bottomOfLink": "I want to join certification",
    "metaassets.sellmodal.title": "Art Purchase Information Page",
    "metaassets.home.more": "flip art",
    "metaassets.sellmodal.name": "Artist",
    "metaassets.sellmodal.certi_no": "Certi_no",
    "metaassets.sellmodal.exchange": "Exchange",
    "metaassets.sellmodal.qty": "Mint",
    "metaassets.sellmodal.price": "Price",
    "metaassets.sellmodal.status": "Status",
    "metaassets.sellmodal.size": "Size",
    "metaassets.sellmodal.detail": "More detail",
    "metaassets.sellmodal.na": "Not yet on sale",
    "metaassets.sellmodal.purchase": "purchase",
    "metaassets.sellmodal.contact": "contact",
    "metaassets.asset.title": "Asset List",
    "metaassets.asset.term": "terms and Conditions：",
    "metaassets.asset.termOfContent": "Any purchaser of a Metaassets Art Asset Certificate (i.e. the Auction Winner and assignee and successors thereafter, the “Purchaser”) acknowledges and agrees to the following terms and conditions when collecting a Metaassets Art Asset Certificate: The owner of the Metaassets art asset certificate (NFT) has all rights to ownership, but any copyright, trademark or any other intellectual property rights of the Metaassets art asset certificate shall not be transferred to the buyer and shall remain with the original author.",
    "metaassets.asset.serach": "Please enter the keywords",
    "metaassets.asset.serachErr": "Cant find result:",
    "metaassets.asset.serachOfName": "Case Name",
    "metaassets.asset.serachOfCreator": "Creator",
    "metaassets.asset.serachOfCerti": "Certi_no",
    "metaassets.asset.nft": "artwork",
    "metaassets.asset.exchange": "Exchange",
    "metaassets.asset.name": "Name",
    "metaassets.asset.investment": "Certification status",
    "metaassets.asset.released": "Certified",
    "metaassets.asset.unreleased": "Uncertified",
    "metaassets.asset.whiteitem": "WhiteItem",
    "metaassets.asset.qty": "Mint: ",
    "metaassets.asset.category": "Category",
    "metaassets.asset.entity": "Entity",
    "metaassets.asset.size": "Size",
    "metaassets.asset.price": "Price",
    "metaassets.asset.crypto": "Crypto",
    "metaassets.asset.creator": "Creator",
    "metaassets.asset.authorize": "Authorize",
    "metaassets.asset.release": "Release",
    "metaassets.asset.certi_no": "Certi_no",
    "metaassets.asset.certi_date": "Certi_date",
    "metaassets.asset.Important": "Important Disclaimer: No part of what we provide constitutes financial advice, legal advice or advice of any kind, regardless of your purpose. Use or rely on our content with caution and at your own risk. Be sure to research, review, analyze, and verify this information yourself before making a decision based on our content. Trading is a high risk activity that can result in significant losses, so please consult your financial advisor before making a decision. The content on our website does not imply any solicitation or offer.",
    "metaassets.coop.head": "Invitation to cooperate",
    "metaassets.footer.pinqueue": "Group site pinqueue",
    "metaassets.footer.pinpinbox": "Group site postwings",
    "metaassets.footer.contact": "contact us",
    "metaassets.footer.service": "Available plans",
    "metaassets.footer.purches": "Order Tracking",
    "metaassets.footer.right": "Copyright © [2022] [Metaassets]. All rights reserved.",
    "metaassets.tutorial.head": "Investment Teaching Steps",
    "metaassets.tutorial.headContent": "Since the trading currency for investing in NFT is Ether, you can join the ranks of collectors in three steps",
    "metaassets.tutorial.step1": "Register a digital currency exchange for easy management of ether and an exchange back to fiat.",
    "metaassets.tutorial.step2": "Apply for a wallet to manage your NFT artwork",
    "metaassets.tutorial.step2-2": "(The mnemonic is equivalent to your deposit book, please manage carefully to prevent reveal)",
    "metaassets.tutorial.step3": "Congratulations! You can start to be a collector, don't forget to join our whitelist group, you can gain the opportunity to get the original NFT painting for free!",
    "metaassets.block.tilte": "The five advantages of an art asset certificate",
    "metaassets.block.item1": "Proof of art asset collection",
    "metaassets.block.item2": "Patent anti-counterfeiting technology",
    "metaassets.block.item3": "Author continues to receive royalties",
    "metaassets.block.item4": "Collection display management",
    "metaassets.block.item5": "Proof of global peer-to-peer transactions",
    "metaassets.service.title1": "Postwings Art Asset Management Service",
    "metaassets.service.title1-1": "《Providing the Best Solutions for Your Artwork》",
    "metaassets.service.content1": "Why establish an exclusive art asset management service?",
    "metaassets.service.content1-1": " Establishing an exclusive art asset management service allows artists to easily manage their artistic creations and collections. When they aren't being displayed in exhibitions or showcased in art catalogs, too many works remain stored away and unseen. Therefore, creating your own exclusive virtual art gallery can significantly increase visibility.",
    "metaassets.service.playbutton-artist": "I am an artist",
    "metaassets.service.playbutton-collect": "I am a collector",
    "metaassets.service.title2": "Common management problems for art collections",
    "metaassets.service.content2-1": "Art collection data is incomplete and not properly managed",
    "metaassets.service.content2-2": "Difficult to locate and appreciate art collections due to large quantities",
    "metaassets.service.content2-3": "Exposure to art collection community is not easy",
    "metaassets.service.content2-4": "Challenges in identifying the source of art collections after being published",
    "metaassets.service.content2-5": "Difficult to find demand for physically exhibiting original art collections",
    "metaassets.service.content2-6": "Issues with verifying the authenticity of art collections after they appreciate in value",
    "metaassets.service.title3": "Four simple steps",
    "metaassets.service.title3-1": "《to establish your exclusive art asset management service》",
    "metaassets.service.content3": "Sign a contract and provide artwork information and files",
    "metaassets.service.content3-1": "Register your artwork in a database and generate a unique art identification card",
    "metaassets.service.content3-2": "Create an online virtual exhibition gallery, share within a community, schedule viewing appointments, and curate",
    "metaassets.service.content3-3": "Create an exclusive smart blockchain art management contract",
    "metaassets.service.title4": "Why should you choose us?",
    "metaassets.service.title4-1": "《Postwings three key service advantages》",
    "metaassets.service.conetent4-1": "Two exclusive patents",
    "metaassets.service.conetent4-1-1": "1. We have two (Republic of China) patents in art certification and anti-counterfeiting artwork verification",
    "metaassets.service.conetent4-2": "Virtual art gallery",
    "metaassets.service.conetent4-2-1": "2. Expert virtual gallery services to manage your exclusive art gallery",
    "metaassets.service.conetent4-3": "Physical exhibition",
    "metaassets.service.conetent4-3-1": "3. Exclusive rental of physical exhibition spaces, along with comprehensive curatorial outsourcing services",
    "metaassets.service.title5": "Learn about the program",
    "metaassets.service.content5": "Plan",
    "metaassets.service.content5-common": "(before tax)",
    "metaassets.service.content5-common-1": "(annual contract)",
    "metaassets.service.content5-common-A": "Classic Artist PackagePricing",
    "metaassets.service.content5-common-B": "Elite Exclusive Marketing Package",
    "metaassets.service.content5-common-C": "Marketing Exposure",
    "metaassets.service.content5-common-2": "Can create payment gateway",
    "metaassets.service.content5-common-3": "Find out more",
    "metaassets.service.content5-A-1": "15 artworks and list management",
    "metaassets.service.content5-A-2": "personal webpage",
    "metaassets.service.content5-A-3": "Provide a 3D gallery",
    "metaassets.service.content5-A-4": "One box of exclusive art business cards and print files",
    "metaassets.service.content5-A-5": "Electronic records of credit card transactions and certificate services (15% transaction fee per transaction)",
    "metaassets.service.content5-A-6": "Additional artwork: $500 each",
    "metaassets.service.content5-A-7": "Marketing Exposure",
    "metaassets.service.content5-A-7-1": "Exposure of POSTWINGS Art Community",
    "metaassets.service.content5-A-7-2": "One news article in POSTWINGS Art News",
    "metaassets.service.content5-A-7-3": "25% consignment fee for artworks",
    "metaassets.service.content5-A-8": "additional purchase discount of NTD$500 /item (original price: 1,000/item)",
    "metaassets.service.content5-B-1": "35 artworks and listing management",
    "metaassets.service.content5-B-2": "Provide a 3D gallery",
    "metaassets.service.content5-B-3": "One box of exclusive art business cards and print files",
    "metaassets.service.content5-B-4": "Electronic record of credit card transactions and certificate services (15% transaction fee per transaction)",
    "metaassets.service.content5-B-5": "Additional artwork: $300 per piece",
    "metaassets.service.content5-B-6": "Marketing Exposure",
    "metaassets.service.content5-B-6-1": "Exposure in POSTWINGS Art Community",
    "metaassets.service.content5-B-6-2": "One news article in POSTWINGS Art News (Guaranteed exposure in 3 media outlets including YAHOO)",
    "metaassets.service.content5-B-6-3": "One professional portrait photo",
    "metaassets.service.content5-B-6-4": "One short social media video introduction",
    "metaassets.service.content5-B-6-5": "25% consignment fee for artworks",
    "metaassets.service.content5-C-1": "150 artworks and listing management",
    "metaassets.service.content5-C-2": "personal webpage",
    "metaassets.service.content5-C-3": "Provides a total of 3 3D galleries (can hold 3 exhibitions) Exclusive artist business cards (5 boxes) and printing files",
    "metaassets.service.content5-C-4": "Eligibility for admission to ART ON AIR program",
    "metaassets.service.content5-C-5": "Electronic transaction records and certificate services with a 15% transaction fee per transaction",
    "metaassets.service.content5-C-6": "Additional artwork at $150 each",
    "metaassets.service.content5-C-7": "Marketing Exposure",
    "metaassets.service.content5-C-7-1": "Exposure in POSTWINGS Art Community & Maggie Art Talks",
    "metaassets.service.content5-C-7-2": "Three BANNER exposures on POSTWINGS official website",
    "metaassets.service.content5-C-7-3": "Add one more piece of work for $150",
    "metaassets.service.content5-C-7-4": "Three exhibition press releases (guaranteed exposure on 3 media platforms including YAHOO)",
    "metaassets.service.content5-C-7-5": "Professional photography of two personal images",
    "metaassets.service.content5-C-7-6": "Production of several short social media video introductions",
    "metaassets.service.content5-C-7-7": " 25% consignment fee for artworks",
    "metaassets.service.title6": "View more exhibition spaces",
    "metaassets.service.title7": "Q&A",
    "metaassets.service.content7-1-1": "1. What is METAASSETS Art Asset Management Certification?",
    "metaassets.service.content7-1-2": "Answer: We are a blockchain-based service dedicated to serving contemporary art. We provide solutions for art asset management, including tracing provenance, facilitating circulation, exposure, tracking, and authentication.",
    "metaassets.service.content7-2-1": "2. Why is an art asset management service necessary?",
    "metaassets.service.content7-2-2": "Answer: Art asset management services allow artists to focus on their creative work. Over time, through their continuous artistic output and participation in exhibitions and sales, artists accumulate a large number of works. Tracking and managing these works can be challenging. Additionally, many works remain stored in storage, never getting to be on public display, and preventing them from being seen by the world. That's why you need an art asset management service. These services provide assistance in organizing, tracking, and promoting works, ensuring that they are properly managed, showcased, and accessible to a wider audience.",
    "metaassets.service.content7-3-1": "3. What should I don't have high-resolution digital files for my works?",
    "metaassets.service.content7-3-2": "Answer: METAASSETS offers exclusive high-resolution digital art photography services using PHASE ONE equipment. Your works can be taken to Post Wings for photography, or on-site photography services can be arranged. Each artwork will be captured at a resolution of 150 megapixels, which can be utilized for subsequent patented art certification and AI-based verification of artwork authenticity.",
    "metaassets.service.content7-4-1": "4. Will my works be downloaded or copied after creating high-resolution digital files?",
    "metaassets.service.content7-4-2": "Answer: The high-resolution files of the artwork are provided solely for issuing certification on the blockchain. They will be made available on online galleries and database websites, but they will be compressed to prevent unauthorized use after potential theft. However, this compression will not affect the appreciation and experience of the artwork.",
    "metaassets.service.content7-5-1": "5. Can I opt for art certification management without utilizing the blockchain?",
    "metaassets.service.content7-5-2": "Answer: Yes, it is possible. You can decide whether or not to upload your works to the blockchain. The advantage of uploading works to the blockchain is that it provides certification and traceability, making it impossible to tamper with the authenticity of the artwork. However, you can also choose to disclose or hide transaction prices on the blockchain.",
    "metaassets.service.content7-6-1": "6. What if I only need access to physical exhibition space? Does that work?",
    "metaassets.service.content7-6-2": "Answer: Yes, that works. METAASSETS has an exclusive partnership with Post Wings, offering physical exhibition space rental service to all partnering artists, galleries, collectors, and associations.",
    "metaassets.service.content7-7-1": "7. Do I have to showcase my virtual gallery on the METAASSETS website?",
    "metaassets.service.content7-7-2": "Answer: No, you can choose whether to display your virtual gallery on the METAASSETS certified database website or keep it for private collection and sharing purposes only.",
    "metaassets.service.content7-8-1": "8. How can I share my virtual gallery?",
    "metaassets.service.content7-8-2": "Answer: Once your virtual gallery is created, it will have its own dedicated URL. You can share it through social media platforms such as Line, Facebook, Instagram, and other social networking sites to friends, family, or collectors. This increases the visibility of your works and provides opportunities for broader exposure and circulation.",
    "metaassets.service.content7-9-1": "9. I'm concerned that a virtual gallery may not be able to convey the visual impact of physical works.",
    "metaassets.service.content7-9-2": "Answer: Don't worry! In our virtual galleries, appointments can be arranged to view the physical works, providing flexibility in experiencing the works in person.",
    "metaassets.service.content7-10-1": "10. How can I obtain an art asset certificate for the works I sell to buyers?",
    "metaassets.service.content7-10-2": "Answer: First, you need to apply for a MetaMask digital wallet. Currently, there are two ways to obtain an art asset certificate. One way is to purchase the art asset certificate when collecting works in the virtual gallery. Upon completing the transaction, the certificate will be transferred to the collector's designated MetaMask wallet. Alternatively, you can directly contact our official METAASSETS team for assistance in issuing the certificate and transferring it to the specified MetaMask wallet.",
    "metaassets.service.content8-11-1": "Art Asset Serial Number Explanation: Each artwork has a unique serial number, with the following code explanations:",
    "metaassets.service.content8-11-2": "ANS: FA represents full authorization mode (including intellectual property rights)./PA represents partial authorization mode (excluding intellectual property rights)./OW represents ownership transfer mode (pure artwork transfer).",
    "metaassets.service.button": " Contact Us",
    "metaassets.service.tel": "Inquiry Hotline",
    "metaassets.tip.title": "Metaassets Certification Code Authorization Explanation:",
    "metaassets.tip.content1": "Lic-FA represents the authorization mode as Full Authorization (including intellectual property rights",
    "metaassets.tip.content2": "Lic-PA represents the authorization mode as Partial Authorization (excluding intellectual property rights",
    "metaassets.tip.content3": "Lic-OW represents the authorization mode as Ownership Transfer (solely for artwork transfer",












}