import React, {useContext, useState} from "react";
import certificating_title from "../assets/img/certificating_title.svg"
import {I18nContext} from "../contexts/lang";
import useI18n from '../hook/useI18n';
const CertificationModal = (props)=>{

    const {t} = useI18n();
    const locale = useContext(I18nContext);

    if(!props.show){
       return null
    }

    return (
        <div className="modal certificating_modal">
            <div className="actions">
                <button className="toggle-button" onClick={props.handleClose} >
                    ✖
                </button>
            </div>
            <div className="modalWrap">
                <div className="content">

                    <div className="certificating_wrap">
                        <section id="tip_title">
                            <h2>{t('metaassets.home.head')}</h2>
                            <h2>{t('metaassets.tip.title')}</h2>
                            <ol className="ul">
                                <li><span>{t('metaassets.tip.content1')}</span></li>
                                <li><span>{t('metaassets.tip.content2')}</span></li>
                                <li><span>{t('metaassets.tip.content3')}</span></li>
                            </ol>
                        </section>
                    </div>

                </div>

            </div>

        </div>
    )
};

export default CertificationModal;