import logo_white from '../assets/img/logo_white.png';
import earth from '../assets/img/earth.svg';
import {Link} from 'react-router-dom';
import useI18n from '../hook/useI18n';
import {HashLink} from 'react-router-hash-link';
import {useContext} from "react";
import { useLocation } from 'react-router-dom';
import { useEffect, useRef } from 'react';

import {ModalDispatchContext, toggleModal} from "../contexts/modal";

const Header = () => {
    const {t, setLocale} = useI18n();
    const location = useLocation();
    const inputRef = useRef(null);
    const modaldispatch = useContext(ModalDispatchContext);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const locale = queryParams.get('locale');

        if(locale === 'zh-TW'){
            // setLocale('zh-TW')
            inputRef.current.click();
        }

    }, [location.search]);

    const handleModal = ()=>{
        return toggleModal(modaldispatch)
    };
    const handleChange = (e) => {
        setLocale(e.target.value)
    };
    return (
        <header className="nav">
            <Link to='/'>
                <div className="logo">
                    <img src={logo_white} alt="logo"/>
                </div>
            </Link>
            <nav role="navigation">
                <div id="menuToggle">

                    <input type="checkbox" id="menu_chkbox"/>

                    <label htmlFor="menu_chkbox">
                        <span></span>
                        <span></span>
                        <span></span>
                    </label>


                    <ul className="menu">
                        <li className="lang_list" onChange={handleChange}>

                            <img className="icon_img" src={earth}/>
                            <input type="radio" name="lang" id="en" value="en-US" defaultChecked={true}/>
                            <label htmlFor="en">Eng</label>
                            <input type="radio" ref={inputRef} name="lang" id="zh-hant" value="zh-TW" />
                            <label htmlFor="zh-hant">繁中</label>
                            <input type="radio"  name="lang" id="ja" value="ja-JP" />
                            <label htmlFor="ja">日本語</label>

                        </li>

                        <li><HashLink to='/#about'>{t('metaassets.head.about')}</HashLink></li>
                        <li><HashLink to="/#art">{t('metaassets.head.art')}</HashLink></li>
                        <li><HashLink to="/#ip">{t('metaassets.head.ip')}</HashLink></li>
                        <li><Link to='/assets'>{t('metaassets.head.asset')}</Link></li>
                        {/*<li><a className="teaching" alt="投資教學" onClick={handleModal} >{t('metaassets.head.tutorial')}</a></li>*/}

                    </ul>
                </div>
                <div className="search">
                    <Link to='/assets'>
                        <div className="box">
                            <span className="text">{t('metaassets.head.serach')}</span>
                            <span className="magnifier">&#8981;</span>
                        </div>
                    </Link>
                </div>
            </nav>
        </header>
    );
};

export default Header;