import useI18n from "../hook/useI18n";
import {useContext} from "react";
import {I18nContext} from "../contexts/lang";
import React from "react";


const SellModal = (props)=>{
    const {t} = useI18n();
    const locale = useContext(I18nContext);
    const {data} = props;
    if(!props.show){
        return null
    }

    let purchase =
        <button className="purchasing_btn" ><a target="_blank"
                                                            href={`https://virtual.metaassets.asia/artworks/${data.certi_no}`}>{t('metaassets.sellmodal.purchase')}</a>
                     </button>;

    if(data.price === 'Ｎ/A'){

        purchase = <button className="purchasing_btn" style={{opacity: 0.5}}><a href="#">{t('metaassets.sellmodal.purchase')}</a>
        </button>;
    }

    return (
        <div className="modal purchasing_modal">
            <div className="actions">
                <button className="toggle-button" onClick={props.handleClose} >
                    ✖
                </button>
            </div>
            <div className="modalWrap">
                <div className="content">


                    <section id="purchasing_title">
                        <h2>{locale.getLocale() === 'zh-TW'? `${data.name}`: `${data.name_en}`}</h2>

                    </section>


                    <div className="certificating_body">
                        <div className="certificating_img_wrap">
                            <img className="certificating_img"
                                 src={data.url}/>
                        </div>

                        <div className="certificating_img_info">

                            <div><span>{t('metaassets.sellmodal.name')}</span><span>{locale.getLocale() === 'zh-TW' ? `${data.creator}` : `${data.creator_en}`}</span></div>
                            <div><span>{t('metaassets.sellmodal.certi_no')}</span><span>{data.certi_no}</span></div>
                            <div><span>{t('metaassets.sellmodal.exchange')}</span>
                                <span><a
                                    href={data.open_sea}>OpenSea</a></span>
                            </div>
                            <div><span>{t('metaassets.sellmodal.qty')}</span><span className="mint_no">{data.qty}</span></div>
                            <div><span>{t('metaassets.sellmodal.price')}</span><span className="eth_price">{data.price === 'Ｎ/A'? t('metaassets.sellmodal.na'):  data.price}</span></div>
                            <div><span>{t('metaassets.sellmodal.status')}</span><span>{t('metaassets.sellmodal.size')}:{data.size} <a target="_blank" href="https://liff.line.me/1645278921-kWRPP32q/?accountId=981hxtxf">{`(${t('metaassets.sellmodal.detail')})`}</a></span></div>
                        </div>

                    </div>


                    <div className="purchasing_footer">

                            {purchase}

                        <button className="service_talk">
                            <a target="_blank"
                               href="https://liff.line.me/1645278921-kWRPP32q/?accountId=981hxtxf">{t('metaassets.sellmodal.contact')}</a>
                        </button>
                    </div>


                </div>

            </div>

        </div>
    )
};

export default SellModal;