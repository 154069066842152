import Header from '../components/Header';
import Footer from '../components/Footer';

const CommonLayout = ({children}) => {

    return (
        <div>
            <Header/>
                <div>
                    {children}
                </div>
            <Footer/>
        </div>
    )
};

export default CommonLayout;