
const ViewImg = ({src, alt})=>{

    const zoomIn = (e)=>{

        let elem = document.createElement('div');
        elem.setAttribute('id','overlay');
        elem.className = 'overlay';
        elem.innerHTML = '<span class="overlayClose" onclick="document.getElementById(\'overlay\').remove();">&#10006;</span>' +
            '<div class="overlayWrap">' +
            '<div class="overlayContent">' +
            '<img src="'+e.target.src+'" class="imgPreview" >' +
            '<h1 class="imgTitle">'+ e.target.alt +'</h1>' +
            '</div>'+
            '</div>';
        document.body.appendChild(elem);
    };

    return (
        <img
             className="art_img"
             onClick={zoomIn}
             src={src}
             alt={alt}
        />
    )
};

export default ViewImg;